import { VimConnectGqlClient } from '@getvim/components-vim-connect-gql-client';

const apiUrl = '/api/graphql';

const createGqlClient = (retryOnNetworkError: boolean = true): VimConnectGqlClient => {
  return new VimConnectGqlClient({
    apiUrl,
    accessTokenCallback: () => null,
    retryOnNetworkError,
  });
};

export class CdeGqlClientManager {
  private static clientsInitialized: boolean = false;
  private static gqlClient: VimConnectGqlClient | undefined;
  private static noRetriesGqlClient: VimConnectGqlClient | undefined;

  private static initializeClients() {
    if (!CdeGqlClientManager.clientsInitialized) {
      CdeGqlClientManager.gqlClient = createGqlClient();
      CdeGqlClientManager.noRetriesGqlClient = createGqlClient(false);
      CdeGqlClientManager.clientsInitialized = true;
    }
  }

  public static setAccessToken = (accessToken: string): void => {
    if (!accessToken) {
      return;
    }

    CdeGqlClientManager.initializeClients();
    CdeGqlClientManager.gqlClient!.setAccessToken(accessToken);
    CdeGqlClientManager.noRetriesGqlClient!.setAccessToken(accessToken);
  };

  public static getClient = (): VimConnectGqlClient => {
    if (!CdeGqlClientManager.gqlClient) {
      throw new Error(
        'Gql clients not initialized, make sure access-token received before calling getClient',
      );
    }

    return CdeGqlClientManager.gqlClient;
  };

  public static getNoRetriesClient = (): VimConnectGqlClient => {
    if (!CdeGqlClientManager.noRetriesGqlClient) {
      throw new Error(
        'Gql clients not initialized, make sure access-token received before calling getNoRetriesClient',
      );
    }

    return CdeGqlClientManager.noRetriesGqlClient;
  };
}
