import React from 'react';
import './TableActionsBar.less';
import { AlignEnum, Button, Paginator, SizeEnum, Text, WeightEnum } from '@getvim/atomic-ui';
import { TableActionsBarProps } from '../../types/table';
import { TABLE_PAGE_SIZE } from '../../consts';

const TableActionsBar: React.FC<TableActionsBarProps> = ({
  paging: { totalResults, setCurrentPage, currentPage, isFetching },
  dataUpdatedAt,
  refetch,
}: TableActionsBarProps) => {
  return (
    <div className="table-actions-bar-container">
      <div className="last-updated-on-container">
        <Text size={SizeEnum['12px']} align={AlignEnum.left} className="last-updated-on-text">
          {'Last updated on: '}
          <span>{dataUpdatedAt}</span>
        </Text>

        <Button
          buttonType="small"
          bgColor="lightGray"
          onClick={refetch}
          className="refresh-button-container"
        >
          <i className="icon-sync icon-sync-properties" />
          <Text className="refresh-button-text" size={SizeEnum['12px']} weight={WeightEnum.light}>
            <span>Refresh</span>
          </Text>
        </Button>
      </div>
      <Paginator
        pagesAmount={Math.ceil(totalResults / TABLE_PAGE_SIZE)}
        onNextPage={setCurrentPage}
        onPreviousPage={setCurrentPage}
        page={currentPage}
        disabled={isFetching}
      />
    </div>
  );
};

export default TableActionsBar;
