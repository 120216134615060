import { useAppState } from '@getvim/internal-vim-os-sdk/react';
import { ExtendedPatientRequestsDetailsResponse } from '../../../api/bff';
import { TABLE_PAGE_SIZE } from '../../../consts';
import { APPROVAL_STATUS, ORDER_BY, SORT_BY } from '../../../types';
import { useCallback, useEffect, useState } from 'react';
import { useFindExtendedPatientRequestsQuery } from '.';
import { appLogger } from '../../../components/App';
import { trackChangePage } from '../../../analytics/trackChangePage';

export interface UseFindExtendedPatientRequestsResponse {
  data?: ExtendedPatientRequestsDetailsResponse;
  isFetching?: boolean;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  dataUpdatedAt: string;
  refetch: () => void;
}

export interface ExtendedPatientRequestsData {
  openRequests: UseFindExtendedPatientRequestsResponse;
  allRequests: UseFindExtendedPatientRequestsResponse;
}

export const useGetPatientRequestsData = (): ExtendedPatientRequestsData => {
  const [openRequestsCurrentPage, setOpenRequestsCurrentPage] = useState<number>(1);
  const [allRequestsCurrentPage, setAllRequestsCurrentPage] = useState<number>(1);

  const { isAppOpen } = useAppState();
  const currentDate = new Date();

  const openRequests = useFindExtendedPatientRequestsQuery({
    queryKey: 'findOpenPatientRequests',
    input: {
      pagination: {
        pageSize: TABLE_PAGE_SIZE,
        pageNumber: openRequestsCurrentPage,
        sortBy: SORT_BY.REVIEW_BEFORE,
        orderBy: ORDER_BY.ASC,
      },
      query: {
        approvalStatuses: [
          {
            status: APPROVAL_STATUS.AUTO_APPROVED,
            filter: {
              toReviewBeforeRange: {
                from: currentDate,
              },
            },
          },
        ],
      },
    },
    isAppOpen,
  });
  const { refetch: openRequestsRefetch, ...openRequestsRest } = openRequests;

  useEffect(() => {
    if (!openRequests.isFetching && !openRequests.data)
      appLogger.warning("Got undefined 'data' for openRequests request", {
        openRequests,
      });
  }, [openRequests]);

  const allRequests = useFindExtendedPatientRequestsQuery({
    queryKey: 'findAllPatientRequests',
    input: {
      pagination: {
        pageSize: TABLE_PAGE_SIZE,
        pageNumber: allRequestsCurrentPage,
        sortBy: SORT_BY.UPDATE_TIME,
        orderBy: ORDER_BY.DESC,
      },
      query: {
        approvalStatuses: [
          { status: APPROVAL_STATUS.REJECTED },
          {
            status: APPROVAL_STATUS.AUTO_APPROVED,
            filter: {
              toReviewBeforeRange: {
                to: currentDate,
              },
            },
          },
        ],
      },
    },
    isAppOpen,
  });
  const { refetch: allRequestsRefetch, ...allRequestsRest } = allRequests;

  useEffect(() => {
    if (!allRequests.isFetching && !allRequests.data)
      appLogger.warning("Got undefined 'data' for allRequests request", {
        allRequests,
      });
  }, [allRequests]);

  useEffect(() => {
    if (!openRequestsRefetch) return;

    openRequestsRefetch();
  }, [openRequestsCurrentPage, openRequestsRefetch]);

  useEffect(() => {
    if (!allRequestsRefetch) return;

    allRequestsRefetch();
  }, [allRequestsCurrentPage, allRequestsRefetch]);

  const refetchAllData = useCallback(() => {
    openRequestsRefetch();
    allRequestsRefetch();
  }, [openRequestsRefetch, allRequestsRefetch]);

  const onOpenRequestsCurrentPage = (page: number) => {
    setOpenRequestsCurrentPage(page);
    handleChangePage(openRequestsCurrentPage, page);
  };

  const onAllRequestsCurrentPage = (page: number) => {
    setAllRequestsCurrentPage(page);
    handleChangePage(allRequestsCurrentPage, page);
  };

  const handleChangePage = (currentPage: number, pageClicked: number) => {
    if (openRequests?.data && allRequests?.data) {
      trackChangePage({
        requests: {
          openRequests: openRequests?.data,
          allRequests: allRequests?.data,
        },
        currentPage,
        pageClicked,
      });
    }
  };

  return {
    openRequests: {
      ...openRequestsRest,
      setCurrentPage: onOpenRequestsCurrentPage,
      currentPage: openRequestsCurrentPage,
      refetch: refetchAllData,
    },
    allRequests: {
      ...allRequestsRest,
      setCurrentPage: onAllRequestsCurrentPage,
      currentPage: allRequestsCurrentPage,
      refetch: refetchAllData,
    },
  };
};
