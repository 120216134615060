import { UserMetadata, useVimUserMetadata } from '@getvim/internal-vim-os-sdk/react';
import { setUserMetadata } from '../logic/user-metadata';
import { useEffect } from 'react';

export const useCdeConfig = (): Partial<UserMetadata> => {
  const vimUserMetadata = useVimUserMetadata();

  useEffect(() => {
    vimUserMetadata.user && setUserMetadata(vimUserMetadata.user);
  }, [vimUserMetadata]);

  return vimUserMetadata;
};
