export enum AppToOSMessageTypes {
  APP_HANDSHAKE = 'APP_HANDSHAKE_MESSAGE',
  APP_HANDSHAKE_ERROR = 'APP_HANDSHAKE_ERROR',
  APP_OAUTH_HANDSHAKE = 'APP_OAUTH_HANDSHAKE_MESSAGE',
  SET_ACTIVATION_STATUS = 'SET_ACTIVATION_STATUS',
  SET_NOTIFICATIONS_INDICATOR = 'SET_NOTIFICATIONS_INDICATOR',
  CLEAR_NOTIFICATIONS_INDICATOR = 'CLEAR_NOTIFICATIONS_INDICATOR',
  DISPLAY_PUSH_NOTIFICATION = 'DISPLAY_PUSH_NOTIFICATION',
  HIDE_NOTIFICATION = 'HIDE_NOTIFICATION',
  SET_TOOLTIP_TEXT = 'SET_TOOLTIP_TEXT',
  SET_DYNAMIC_APP_SIZE = 'SET_DYNAMIC_APP_SIZE',
  POP_UP = 'POP_UP',
  COPY_TO_CLIPBOARD = 'COPY_TO_CLIPBOARD',
  EXECUTE_RESOURCE_ENHANCEMENT = 'EXECUTE_RESOURCE_ENHANCEMENT',
  UPDATE_RESOURCE = 'UPDATE_RESOURCE',
  TRACK_ANALYTICS = 'TRACK_ANALYTICS',
  EXECUTE_ACTION = 'EXECUTE_ACTION',
  ENABLE_LAUNCH_BUTTONS = 'ENABLE_LAUNCH_BUTTONS',
}
