import { inject, injectable } from 'inversify';
import { LoggerGlobalConfig } from '../configs';
import { BackendService, LogLevel, LogMetadata, LoggerTags } from '../types';
import { getSumoCapacitorUrlByEnv, getUrlByEnv } from './get-url-by-env';

const TIMEOUT = 150_000;

interface BuildRequestInitInput {
  scope: string;
  logLevel: LogLevel;
  message: string;
  clientTime: number;
  error?: Error;
  data?: unknown;
  tags?: LoggerTags;
  metadata?: Partial<LogMetadata>;
}

@injectable()
export class ServerTransport {
  private useCapacitor = true;
  constructor(@inject(LoggerGlobalConfig) private loggerGlobalConfig: LoggerGlobalConfig) {
    const useCapacitorByEnv = (window as any).$vim_environment?.VIM_USE_SUMO_CAPACITOR_V2;

    if (useCapacitorByEnv != undefined) {
      this.useCapacitor = useCapacitorByEnv?.toLowerCase() === 'true';
    }
  }

  private buildOldRequestInit({
    scope,
    logLevel,
    message,
    clientTime,
    error,
    data,
    tags,
    metadata,
  }: BuildRequestInitInput): RequestInit {
    return {
      method: 'POST',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        ...(this.loggerGlobalConfig.accessToken
          ? { authorization: this.loggerGlobalConfig.accessToken }
          : undefined),
      },
      body: JSON.stringify({
        scope,
        message,
        data: {
          error: error ? this.errorToObject(error) : undefined,
          ...(data ?? {}),
        },
        logLevel,
        clientTime,
        metadata,
        tags,
      }),
    };
  }

  private buildCapacitorRequestInit({
    scope,
    logLevel,
    message,
    clientTime,
    error,
    data,
    tags,
    metadata,
  }: BuildRequestInitInput): RequestInit {
    return {
      method: 'POST',
      headers: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Content-Type': 'application/json',
        ...(this.loggerGlobalConfig.accessToken
          ? { authorization: this.loggerGlobalConfig.accessToken }
          : undefined),
      },
      body: JSON.stringify({
        log: {
          level: logLevel,
          scope,
          message,
          metadata: {
            scope,
            data: {
              error: error ? this.errorToObject(error) : undefined,
              ...(data ?? {}),
            },
            clientTime,
            metadata,
            tags,
          },
        },
      }),
    };
  }

  public async log(
    scope: string,
    logLevel: LogLevel,
    message: string,
    clientTime: number,
    error?: Error,
    data?: unknown,
    tags?: LoggerTags,
    metadata?: Partial<LogMetadata>,
    timeout: number = TIMEOUT,
  ) {
    if (origin.includes('localhost')) return;
    const controller = new AbortController(); // https://developer.chrome.com/blog/abortable-fetch/ - Note: It's ok to call .abort() after the fetch has already completed, fetch simply ignores it.
    setTimeout(() => {
      controller.abort();
    }, timeout);

    if (this.useCapacitor) {
      const request = this.buildCapacitorRequestInit({
        scope,
        logLevel,
        message,
        clientTime,
        error,
        data,
        tags,
        metadata,
      });
      return await fetch(`${getSumoCapacitorUrlByEnv(origin, logLevel)}`, {
        ...request,
        signal: controller.signal,
      }).catch((error) => {
        if (error?.name !== 'AbortError') {
          throw error;
        }
      });
    } else {
      const request = this.buildOldRequestInit({
        scope,
        logLevel,
        message,
        clientTime,
        error,
        data,
        tags,
        metadata,
      });
      return await fetch(`${getUrlByEnv(origin, BackendService.LOGGER, 'api/log')}`, {
        ...request,
        signal: controller.signal,
      }).catch((error) => {
        if (error?.name !== 'AbortError') {
          throw error;
        }
      });
    }
  }

  private errorToObject(error: Error) {
    const errorObject = {};
    for (const prop of Object.getOwnPropertyNames(error)) {
      errorObject[prop] = error[prop];
    }
    return errorObject;
  }
}
