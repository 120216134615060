import { OSToAppMessageEvent, OSToAppMessageTypes } from '@getvim/vim-os-api';
import equal from 'fast-deep-equal';
import { omit } from 'lodash-es';
import { EHR } from '@getvim/vim-os-sdk-api';
import { EhrResourceEnhancementsExecutor, OsCommunicator } from '.';
import { InternalResourceUpdater } from './ehr-updates/internalResourceUpdater';
import { EhrResourceUpdater } from './ehr-updates/resourceUpdater';
import { OSToAppEhrConvertor } from './os-to-app-ehr-convertor/interface';
import { Subscription } from './subscription';

function resourceDeepEquals<RESOURCE extends EHR.EHR_STATE_TEMPLATE[EHR.EHRResource]>(
  incomingEHRResource: RESOURCE,
  currentEhrResource: RESOURCE,
): boolean {
  const fieldsToIgnore = ['getProblemList'];
  const incomingResource = omit(incomingEHRResource, fieldsToIgnore);
  const currentResource = omit(currentEhrResource, fieldsToIgnore);
  return equal(incomingResource, currentResource);
}

export class EhrAPI<EHR_STATE extends EHR.EHR_STATE_TEMPLATE>
  extends Subscription<EHR.EHR_STATE_RESOURCES<EHR_STATE>>
  implements EHR.EhrAPI<EHR_STATE>
{
  constructor(
    osCommunicator: OsCommunicator,
    incomingOsMessagePort: MessagePort,
    oSToAppEhrConvertor: OSToAppEhrConvertor<EHR_STATE>,
    internalResourceUpdater: InternalResourceUpdater,
  ) {
    super({});
    this.resourceUpdater = new EhrResourceUpdater(internalResourceUpdater);
    const ehrResourceEnhancements = new EhrResourceEnhancementsExecutor(osCommunicator, this);
    incomingOsMessagePort.addEventListener(
      'message',
      (message: OSToAppMessageEvent<{ withPII: true }>) => {
        switch (message?.data?.type) {
          case OSToAppMessageTypes.EHR_STATE_CHANGE: {
            const { updatableFields, ...ehrState } = message.data.payload;
            const updatedState: EHR.EHR_STATE_RESOURCES<EHR_STATE> = oSToAppEhrConvertor.convertEhr(
              ehrState,
              ehrResourceEnhancements,
            );
            Object.values(EHR.EHRResource).forEach((resource) => {
              if (!resourceDeepEquals(this.ehrState[resource], updatedState[resource])) {
                // TODO we satisfy the types because ts cant recognize the specific resource assertion and fails
                switch (resource) {
                  case EHR.EHRResource.patient: {
                    this.ehrState[resource] = updatedState[resource];
                    break;
                  }
                  case EHR.EHRResource.encounter: {
                    this.ehrState[resource] = updatedState[resource];
                    break;
                  }
                  case EHR.EHRResource.referral: {
                    this.ehrState[resource] = updatedState[resource];
                    break;
                  }
                  case EHR.EHRResource.orders: {
                    this.ehrState[resource] = updatedState[resource];
                    break;
                  }
                  default:
                    this.ehrState[resource] = updatedState[resource];
                }

                this.dispatch(resource, updatedState[resource]);
              }
            });
            break;
          }
        }
      },
    );
  }
  public ehrState: EHR.EHR_STATE_RESOURCES<EHR_STATE> = {};
  public resourceUpdater: EHR.IResourceUpdater;
}
