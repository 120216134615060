import { FileExtension, FileMetadata } from '../../types';

const generateBlobFromJson = (json: any) =>
  new Blob([JSON.stringify(json, null, 2)], { type: 'application/json' });

export const createJsonFile = ({ fileName, data }) => {
  const jsonFile: FileMetadata = {
    name: fileName,
    data: generateBlobFromJson(data),
    extension: FileExtension.JSON,
  };

  return jsonFile;
};
