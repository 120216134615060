// eslint-disable-next-line check-file/filename-naming-convention
import { BackendService, LogLevel } from '../types';

export const getUrlByEnv = (origin: string, service: BackendService, path: string): string => {
  if (origin.includes('staging'))
    return new URL(path, `https://${service}.staging.getvim.com`).href;
  else if (origin.includes('demo')) return new URL(path, `https://${service}.demo.getvim.com`).href;
  else if (origin.includes('localhost')) {
    return new URL(path, `http://localhost:${mapBackendServiceToPort(service)}`).href;
  } else if (origin.includes('dev')) return new URL(path, `https://${service}.dev.devim.io`).href;
  else return new URL(path, `https://${service}.getvim.com`).href;
};

export function getEnvByOrigin(origin: string): string {
  if (origin.includes('staging')) {
    return 'staging';
  } else if (origin.includes('demo')) {
    return 'demo';
  } else if (origin.includes('dev')) {
    return 'dev';
  } else if (
    origin.includes('localhost') ||
    origin.includes('your_mac_ip') ||
    origin.includes('10.211.55.2')
  ) {
    return 'dev';
  }
  return 'prod';
}

export const getSumoCapacitorUrlByEnv = (origin: string, logLevel: LogLevel): string => {
  if (logLevel === LogLevel.INFO) {
    logLevel = 'other' as LogLevel;
  } else if (logLevel === LogLevel.WARNING) {
    logLevel = 'warning' as LogLevel;
  }
  const path = `${logLevel}.log`;
  const env = getEnvByOrigin(origin);
  return new URL(path, `https://client-logs${env === 'prod' ? '.' : `.${env}.`}getvim.com/`).href;
};

const mapBackendServiceToPort = (service: BackendService) => {
  switch (service) {
    case BackendService.CONSOLE:
      return '6002';
    case BackendService.LOGGER:
      return '6025';
    default:
      throw new Error(`Unexpected service value: ${service}`);
  }
};
