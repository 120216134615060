import NodeCache from 'node-cache';

const DEFAULT_TTL = 10 * 60 * 1000;

export class JobsCacheManager<K, V> {
  private cache: NodeCache;

  constructor() {
    this.cache = new NodeCache({
      stdTTL: (window?.$vim_environment?.VIM_TTL_CACHE as number) || DEFAULT_TTL,
    });
  }

  private stringifyKey(key: K | string | number): string {
    if (typeof key === 'string' || typeof key === 'number') {
      return key.toString();
    }
    return JSON.stringify(key);
  }

  set(key: K | string | number, value: V): void {
    this.cache.set(this.stringifyKey(key), value);
  }

  get(key: K | string | number): V | undefined {
    return this.cache.get(this.stringifyKey(key)) as V | undefined;
  }
}
