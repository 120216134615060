import {
  actionButtonSchema as osActionButtonSchema,
  pushNotificationPayloadSchema as osPushNotificationPayloadSchema,
  buttonStyleSchema,
} from '@getvim/vim-os-api';
import { z } from 'zod';

const osAction = osActionButtonSchema;

export const actionButtonSchema = osAction
  .omit({ id: true })
  .merge(z.object({ callback: z.function() }));

const osPushNotificationSchema = osPushNotificationPayloadSchema;

export const pushNotificationPayloadSchema = osPushNotificationSchema
  .omit({ actionButtons: true })
  .merge(
    z.object({
      actionButtons: z
        .object({
          leftButton: actionButtonSchema.optional(),
          rightButton: actionButtonSchema.optional(),
        })
        .optional(),
    }),
  );

export type ActionButton = z.infer<typeof actionButtonSchema>;
export type PushNotificationPayload = z.infer<typeof pushNotificationPayloadSchema>;

const buttonStyle = buttonStyleSchema;
export type ButtonStyle = z.infer<typeof buttonStyle>;
export const ButtonStyle = buttonStyle.enum;
