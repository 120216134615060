import { CdeAnalyticsClient } from './types/analyticsManager.types';
import { CdeAppAnalyticsEvents, WidgetSource } from './types/cdeAppAnalytics.types';

interface CdeAnalyticsBaseProperties {
  datasource?: WidgetSource;
}

export class AnalyticsManager {
  private baseProps: CdeAnalyticsBaseProperties = {};
  private static analyticsClient: CdeAnalyticsClient;

  public static setAnalyticsClient(client: CdeAnalyticsClient) {
    AnalyticsManager.analyticsClient = client;
  }

  public upsertBaseProperties(properties: Partial<CdeAnalyticsBaseProperties>) {
    const { datasource } = properties;
    this.baseProps = { ...this.baseProperties, datasource };
  }

  public get baseProperties() {
    return this.baseProps;
  }

  public getDatasource() {
    return this.baseProperties.datasource;
  }

  public track<T extends keyof CdeAppAnalyticsEvents>(
    event: T,
    eventProperties: CdeAppAnalyticsEvents[T],
  ) {
    AnalyticsManager.analyticsClient.trackAnalytics({
      event,
      properties: {
        ...eventProperties,
        ...this.baseProperties,
        is_vim_os: true,
      },
    });
  }
}

export const analyticsManager = new AnalyticsManager();
