import { getLogger } from '../logger';
import { base64ToBuffer, bufferToString } from './utils';

const encryptionLogger = getLogger({ scope: 'e2e-encryption' });

export interface EncryptedDataPayload {
  encryptedMessage: string;
  encryptedMetadata: string;
}

class Encryption {
  private cryptoKeyPair?: CryptoKeyPair;

  private exportedPublicKey?: string;

  private static async exportPublicKey(publicKey: CryptoKey): Promise<string> {
    const exportedAsBuffer = await window.crypto.subtle.exportKey('spki', publicKey);
    const exportedAsString = bufferToString(exportedAsBuffer);
    const exportedAsBase64 = window.btoa(exportedAsString);

    return exportedAsBase64;
  }

  public init = async () => {
    if (!window.crypto.subtle) {
      encryptionLogger.info('Skipping generate encryption keys for insecure URL');
      return;
    }

    encryptionLogger.info('Generating new private and public key for end to end encryption');
    this.cryptoKeyPair = await window.crypto.subtle.generateKey(
      {
        name: 'RSA-OAEP',
        modulusLength: 2048,
        publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
        hash: 'SHA-256',
      },
      false,
      ['decrypt'],
    );
    this.exportedPublicKey = await Encryption.exportPublicKey(this.cryptoKeyPair.publicKey);

    encryptionLogger.info('Generated crypto key pair for end to end encryption successfully');
  };

  public getPublicKey(): string | undefined {
    return this.exportedPublicKey;
  }

  private async decryptAsymmetricEncryption(encryptedData: string): Promise<string> {
    if (!this.cryptoKeyPair?.privateKey)
      throw new Error('Missing private key. Did you call generateCryptoKeys?');

    const decryptedBuffer = await window.crypto.subtle.decrypt(
      {
        name: 'RSA-OAEP',
      },
      this.cryptoKeyPair.privateKey,
      base64ToBuffer(encryptedData),
    );

    return bufferToString(decryptedBuffer);
  }

  public async decrypt({ encryptedMessage, encryptedMetadata }: EncryptedDataPayload) {
    try {
      if (!this.exportedPublicKey) {
        return window.atob(encryptedMessage);
      }

      // Use our private key to decrypt the encrypted metadata
      const stringifiedDecryptedMetadata = await this.decryptAsymmetricEncryption(
        encryptedMetadata,
      );
      const encryptionMetadata = JSON.parse(stringifiedDecryptedMetadata);
      // Import the symmetric key that's provided in the metadata
      const importedSymmetricKey = await window.crypto.subtle.importKey(
        'raw',
        base64ToBuffer(encryptionMetadata.symmetricKey),
        'AES-GCM',
        true,
        ['decrypt'],
      );

      // Decrypt the encrypted message using the given symmetric key
      const decryptedMessageBuffer = await window.crypto.subtle.decrypt(
        { name: 'AES-GCM', iv: base64ToBuffer(encryptionMetadata.iv) },
        importedSymmetricKey,
        base64ToBuffer(encryptedMessage),
      );
      return bufferToString(decryptedMessageBuffer);
    } catch (error) {
      encryptionLogger.error('Failed to decrypt encrypted data', { error });
      throw error;
    }
  }
}

export const encryption = new Encryption();
