import {
  ApplicationManifest,
  DefaultVimSDKConfigurations,
  EHR_EVENT,
  VimSDKConfigurations,
  AppStoreServiceId,
  EhrUser,
  OrganizationIdentifiers,
} from '@getvim-os/types';
import { OSEhrStateWithPII, OSEhrState } from './ehrState';
import { UpdatableEhrFields } from './ehrUpdates';
import { EnrichedEhrPayload, EnrichedUserPayload } from './os-service';
import type { LaunchButtonRealEstates, ApplicationState, OSMessageResponseType } from '../schemas';

export type VimAuth = {
  /**
   * @deprecated use oauth flow instead
   */
  accessToken?: string;
};

export type AppState = ApplicationState;

export enum OSToAppMessageTypes {
  APP_HANDSHAKE_ACK = 'APP_HANDSHAKE_ACK',
  APP_HANDSHAKE_ERROR = 'APP_HANDSHAKE_ERROR',
  AUTH_TOKEN_SET = 'AUTH_TOKEN_SET',
  EHR_STATE_CHANGE = 'EHR_STATE_CHANGE',
  ON_EHR_EVENT = 'ON_EHR_EVENT',
  ON_NOTIFICATION_ACTION = 'ON_NOTIFICATION_ACTION',
  SERVICE_ENRICHED_EHR = 'SERVICE_ENRICHED_EHR',
  SERVICE_ENRICHED_USER = 'SERVICE_ENRICHED_USER',
  SERVICE_CUSTOM_MESSAGE = 'SERVICE_CUSTOM_MESSAGE',
  APP_STATE = 'APP_STATE',
  HEALTH_CHECK = 'HEALTH_CHECK',
  APP_LAUNCH_BUTTON_CLICKED = 'APP_LAUNCH_BUTTON_CLICKED',
}

export type HandshakePayload = {
  user: Partial<EhrUser>;
  organization: Partial<OrganizationIdentifiers>;
  manifestSupport: ApplicationManifest;
  ehrVendor: string | undefined;
  deviceId: string;
  bareboneType: string;
  bareboneVersion: string;
  adapterId: string;
  userSessionId: string;
  hostname?: string;
  features?: {
    skipUpdateValidation?: boolean;
  };
};

export type HandshakeErrorPayload = {
  type: OSMessageResponseType;
  data: any;
};

export type AppEhrState<CONFIG extends VimSDKConfigurations = DefaultVimSDKConfigurations> =
  CONFIG['withPII'] extends true ? OSEhrStateWithPII : OSEhrState;

export type EhrStateChangePayload<CONFIG extends VimSDKConfigurations = VimSDKConfigurations> =
  AppEhrState<CONFIG> & {
    /**
     * The updatable fields supported by the App
     */
    updatableFields?: UpdatableEhrFields;

    /**
     * The updatable fields supported by the EHR
     */
    ehrUpdatableFields?: UpdatableEhrFields;
  };

export type PushNotificationResponse =
  | {
      event: 'error';
      payload: {
        notificationId?: string;
        reason: string;
      };
    }
  | {
      event: 'action';
      payload: {
        notificationId?: string;
        actionId: string;
      };
    };

type BaseServiceMessage = {
  serviceId: AppStoreServiceId;
};

export type ServiceEnrichedEhrPayload = BaseServiceMessage & EnrichedEhrPayload;

export type ServiceEnrichedUserPayload = BaseServiceMessage & EnrichedUserPayload;

export type ServiceCustomPayload = BaseServiceMessage & {
  data: any;
};
export type AppLaunchButtonClickPayload = {
  realEstate: LaunchButtonRealEstates;
};

export type OSToAppMessageEventData<CONFIG extends VimSDKConfigurations = VimSDKConfigurations> =
  | {
      type: OSToAppMessageTypes.APP_HANDSHAKE_ACK;
      payload: HandshakePayload;
    }
  | {
      type: OSToAppMessageTypes.APP_HANDSHAKE_ERROR;
      payload: HandshakeErrorPayload;
    }
  | {
      type: OSToAppMessageTypes.AUTH_TOKEN_SET;
      payload: {
        vimAuth: VimAuth;
      };
    }
  | {
      type: OSToAppMessageTypes.EHR_STATE_CHANGE;
      payload: EhrStateChangePayload<CONFIG>;
    }
  | {
      type: OSToAppMessageTypes.ON_EHR_EVENT;
      payload: EHR_EVENT;
    }
  | {
      type: OSToAppMessageTypes.ON_NOTIFICATION_ACTION;
      payload: PushNotificationResponse;
    }
  | {
      type: OSToAppMessageTypes.SERVICE_ENRICHED_EHR;
      payload: ServiceEnrichedEhrPayload;
    }
  | {
      type: OSToAppMessageTypes.SERVICE_ENRICHED_USER;
      payload: ServiceEnrichedUserPayload;
    }
  | {
      type: OSToAppMessageTypes.SERVICE_CUSTOM_MESSAGE;
      payload: ServiceCustomPayload;
    }
  | {
      type: OSToAppMessageTypes.APP_STATE;
      payload: AppState;
    }
  | {
      type: OSToAppMessageTypes.HEALTH_CHECK;
      payload: undefined;
    }
  | {
      type: OSToAppMessageTypes.APP_LAUNCH_BUTTON_CLICKED;
      payload: AppLaunchButtonClickPayload;
    };

export type OSToAppMessageEventDataWithResponses<
  CONFIG extends VimSDKConfigurations = VimSDKConfigurations,
> = Extract<OSToAppMessageEventData<CONFIG>, Pick<OSToAppMessageResponses, 'type'>>;

export type OSToAppMessageResponses = {
  type: OSToAppMessageTypes.HEALTH_CHECK;
  response: undefined;
};

export type OSToAppMessageResponseMap = {
  [K in OSToAppMessageResponses['type']]: Extract<OSToAppMessageResponses, { type: K }>['response'];
};

export interface OSToAppMessageEvent<CONFIG extends VimSDKConfigurations = VimSDKConfigurations>
  extends MessageEvent {
  data: OSToAppMessageEventData<CONFIG>;
}
