import { analyticsManager } from './analyticsManager';
import { ActionPayload } from './types/cdeAppAnalytics.types';

export const trackActionClicked = ({
  action_type,
  data_requester,
  vim_patient_id,
  pseudonymized_patient_id,
  extraction_status,
  extraction_from_date,
  extraction_to_date,
  to_review_before_date,
  approval_status,
  extracted_with_first_name,
  extracted_with_last_name,
}: ActionPayload): void => {
  analyticsManager.track('cde_action_clicked', {
    app_source_displayed: analyticsManager.getDatasource(),
    data_requester,
    action_type,
    vim_patient_id,
    pseudonymized_patient_id,
    extraction_status,
    extraction_from_date,
    extraction_to_date,
    to_review_before_date,
    approval_status,
    extracted_with_first_name,
    extracted_with_last_name,
  });
};
