import { Subscription } from '@getvim/dynamic-vim-os-sdk/sdk';
import {
  AppLaunchButtonClickPayload,
  AppToOSMessageTypes,
  EnableLaunchButtonPayload,
  OSToAppMessageEvent,
  OSToAppMessageTypes,
} from '@getvim/vim-os-api';
import { InternalOsCommunicator } from './internalOsCommunicator';

export class LaunchButtonApi extends Subscription<{
  launchButtonClicked?: AppLaunchButtonClickPayload;
}> {
  public lastLaunchButtonClick?: AppLaunchButtonClickPayload;

  constructor(private osCommunicator: InternalOsCommunicator, incomingOsMessagePort: MessagePort) {
    super({
      launchButtonClicked: undefined,
    });
    incomingOsMessagePort.addEventListener(
      'message',
      (message: OSToAppMessageEvent<{ withPII: true }>) => {
        switch (message?.data?.type) {
          case OSToAppMessageTypes.APP_LAUNCH_BUTTON_CLICKED: {
            this.lastLaunchButtonClick = message.data.payload;
            this.dispatch('launchButtonClicked', message.data.payload);
          }
        }
      },
    );
  }

  public enableLaunchButtons = (enabledLaunchButtons: EnableLaunchButtonPayload) => {
    this.osCommunicator.sendMessageToOS({
      type: AppToOSMessageTypes.ENABLE_LAUNCH_BUTTONS,
      payload: enabledLaunchButtons,
    });
  };
}
