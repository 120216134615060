import React, { FC, useState, useEffect } from 'react';
import classNames from 'classnames';
import './index.less';

export const PAGINATOR_INITIAL_PAGE = 1;

export interface PaginatorProps {
  className?: string;
  page?: number;
  pagesAmount: number;
  onNextPage(page: number): void;
  onPreviousPage(page: number): void;
  disabled?: boolean;
}

export const Paginator: FC<PaginatorProps> = ({
  pagesAmount,
  page: pageProp,
  onNextPage,
  onPreviousPage,
  className,
  disabled,
}) => {
  const [pageState, setPageState] = useState(1);

  const page = pageProp ?? pageState;

  useEffect(() => {
    if (pageProp) {
      setPageState(pageProp);
    }
  }, [pageProp]);

  const goPrevious = () => {
    setPageState((currentPage) => {
      onPreviousPage(currentPage - 1);
      return currentPage - 1;
    });
  };

  const goNext = () => {
    setPageState((currentPage) => {
      onNextPage(currentPage + 1);
      return currentPage + 1;
    });
  };

  return (
    <div className={classNames('paginator', className)}>
      <button
        disabled={disabled || page === PAGINATOR_INITIAL_PAGE}
        onClick={goPrevious}
        type="button"
      >
        <i className="icon-chevron-left" />
      </button>
      <div className="label">
        Page {page} of {pagesAmount}
      </div>
      <button disabled={disabled || page >= pagesAmount} onClick={goNext} type="button">
        <i className="icon-chevron-right" />
      </button>
    </div>
  );
};
