import { gql } from '@apollo/client';
import { CdeGqlClientManager } from './gqlClient';
import {
  APPROVAL_STATUS,
  EncryptedPatient,
  PatientDetails,
  PATIENT_REQUEST_FAILED_DETAILS,
  PATIENT_REQUEST_STATUS,
} from '../../types';
import { encryption } from '../../components/e2e-encryption';

export interface PaginationOptions {
  pageNumber: number;
  pageSize: number;
  sortBy?: string;
  orderBy?: string;
}

interface ToReviewBeforeRange {
  to?: Date;
  from?: Date;
}

interface ApprovalStatusFilter {
  toReviewBeforeRange?: ToReviewBeforeRange;
}

interface ApprovalStatusQuery {
  status: APPROVAL_STATUS;
  filter?: ApprovalStatusFilter;
}

interface ExtendedPatientRequestsQuery {
  approvalStatuses?: ApprovalStatusQuery[];
}

export interface ExtendedPatientRequestsInput {
  publicKey?: string;
  pagination?: PaginationOptions;
  query?: ExtendedPatientRequestsQuery;
}

interface ExtractionDateRange {
  fromDate: string;
  untilDate: string;
  finishedAt?: Date | null;
}

interface PatientRequestExtractionUser {
  email?: string;
  linkedEhrUser?: string;
  firstName?: string;
  lastName?: string;
}

export interface PatientRequestApprovalStatus {
  updateAt: Date;
  updatedBy: string;
  status: APPROVAL_STATUS;
}

interface ExtendedPatientRequests {
  id: string;
  dataRequester: string;
  extractedWith?: PatientRequestExtractionUser;
  extractionDateRange: ExtractionDateRange;
  status: PATIENT_REQUEST_STATUS;
  toReviewBefore: string;
  approvalStatus: PatientRequestApprovalStatus;
  encryptedPatient: EncryptedPatient;
  details: PATIENT_REQUEST_FAILED_DETAILS;
  sharedAt: Date;
  originalRequester: string;
  vimPatientId?: string;
  expirationDate?: string;
}

interface ExtendedPatientRequestsResponse {
  extendedPatientRequests: ExtendedPatientRequests[];
  paginationResult: { pageNumber: number; pageSize: number; totalResults: number };
}

export interface ExtendedPatientRequestsDetails {
  id: string;
  dataRequestId: string;
  dataRequester: string;
  extractedWith?: PatientRequestExtractionUser;
  extractionDateRange: ExtractionDateRange;
  status: PATIENT_REQUEST_STATUS;
  toReviewBefore: string;
  patientName: {
    firstName: string;
    lastName: string;
  };
  dateOfBirth: string;
  approvalStatus: PatientRequestApprovalStatus;
  details?: PATIENT_REQUEST_FAILED_DETAILS;
  sharedAt?: Date;
  originalRequester?: string;
  pseudonymizedId?: string;
  vimPatientId?: string;
  expirationDate?: string;
}

export interface ExtendedPatientRequestsDetailsResponse {
  extendedPatientRequestsDetails: ExtendedPatientRequestsDetails[];
  paginationResult: { pageNumber: number; pageSize: number; totalResults: number };
}

const FIND_EXTENDED_PATIENT_REQUESTS_QUERY = gql`
  query findExtendedPatientRequests($input: FindExtendedPatientRequestsInput!) {
    findExtendedPatientRequests(input: $input) {
      extendedPatientRequests {
        id
        dataRequester
        extractedWith {
          email
          linkedEhrUser
          firstName
          lastName
        }
        extractionDateRange {
          fromDate
          untilDate
          finishedAt
        }
        status
        toReviewBefore
        encryptedPatient {
          encryptedMetadata
          encryptedPatientDetails
        }
        approvalStatus {
          updatedAt
          updatedBy
          status
        }
        sharedAt
        details
        originalRequester
        vimPatientId
        expirationDate
      }
      paginationResult {
        pageNumber
        pageSize
        totalResults
      }
    }
  }
`;

export async function findExtendedPatientRequests(
  input?: Partial<ExtendedPatientRequestsInput>,
): Promise<ExtendedPatientRequestsDetailsResponse | undefined> {
  const gqlClient = await CdeGqlClientManager.getClient();
  const result = await gqlClient.query<
    { findExtendedPatientRequests: ExtendedPatientRequestsResponse },
    { input: ExtendedPatientRequestsInput }
  >({
    query: FIND_EXTENDED_PATIENT_REQUESTS_QUERY,
    variables: { input: { publicKey: encryption.getPublicKey(), ...input } },
    fetchPolicy: 'no-cache',
  });

  const { extendedPatientRequests, paginationResult } =
    result.data?.findExtendedPatientRequests ?? {};

  return {
    paginationResult,
    extendedPatientRequestsDetails: await Promise.all(
      extendedPatientRequests.map(async (extendedPatientRequest) => {
        const {
          id,
          dataRequester,
          extractedWith,
          extractionDateRange,
          status,
          toReviewBefore,
          approvalStatus,
          encryptedPatient: { encryptedMetadata, encryptedPatientDetails },
          details,
          sharedAt,
          originalRequester,
          vimPatientId,
          expirationDate,
        } = extendedPatientRequest;

        const decryptedStringifiedPatient = await encryption.decrypt({
          encryptedMessage: encryptedPatientDetails,
          encryptedMetadata,
        });

        const {
          firstName,
          lastName,
          clientInternalRecordId,
          dateOfBirth,
          pseudonymizedId,
        }: PatientDetails = JSON.parse(decryptedStringifiedPatient);
        return {
          id,
          dataRequestId: clientInternalRecordId,
          dataRequester,
          extractedWith,
          extractionDateRange,
          status,
          toReviewBefore,
          approvalStatus,
          patientName: { firstName, lastName },
          dateOfBirth,
          details,
          sharedAt,
          originalRequester,
          pseudonymizedId,
          vimPatientId,
          expirationDate,
        };
      }),
    ),
  };
}
