import { UserMetadata } from '@getvim/internal-vim-os-sdk/react';
import { logger as loggerInstance } from '@getvim/vim-connect-logger';

export const setUserMetadata = (userMetadata: UserMetadata['user']) => {
  return loggerInstance.setUserMetadata({
    ...userMetadata,
    id:
      'identifiers' in userMetadata
        ? userMetadata.identifiers?.vimUserID
        : 'externalId' in userMetadata
        ? userMetadata.externalId
        : undefined,
  });
};
