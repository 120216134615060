import {
  HandshakePayload,
  OSToAppMessageEvent,
  OSToAppMessageTypes,
  VimAuth,
} from '@getvim/vim-os-api';
import { InternalSDKToAppMessageTypes } from '../types';
import type { SessionContext } from '@getvim/vim-os-sdk-api';
import { VimEventTarget } from './vimEventTarget';
import { convertUser, SessionContextApi } from '@getvim/dynamic-vim-os-sdk/sdk';

export type InternalSessionContext = SessionContext.SessionContext & {
  vimAuth?: VimAuth;
  user: SessionContext.SessionContext['user'] & Pick<HandshakePayload['user'], 'roles'>;
};

export class InternalSessionContextApi extends SessionContextApi implements InternalSessionContext {
  constructor(
    handshakePayload: HandshakePayload,
    incomingOsMessagePort: MessagePort,
    eventTarget: VimEventTarget,
    tokensResponse?: { idToken: string },
  ) {
    super({ handshakePayload, tokensResponse });

    this.user = {
      ...convertUser(handshakePayload.user),
      roles: handshakePayload.user.roles,
    };
    incomingOsMessagePort.addEventListener(
      'message',
      (message: OSToAppMessageEvent<{ withPII: true }>) => {
        switch (message?.data?.type) {
          case OSToAppMessageTypes.AUTH_TOKEN_SET: {
            const vimAuth = message.data.payload?.vimAuth;
            this.vimAuth = {
              ...(this.vimAuth || {}),
              accessToken: vimAuth.accessToken,
            };
            const event = new CustomEvent<VimAuth>(InternalSDKToAppMessageTypes.AUTH_TOKEN_SET, {
              detail: vimAuth,
            });
            eventTarget.dispatchEvent(event);
            break;
          }
        }
      },
    );
  }

  public vimAuth?: VimAuth;
  user: InternalSessionContext['user'];
}
