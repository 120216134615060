import { OSToAppMessageEvent, OSToAppMessageTypes } from '@getvim/vim-os-api';
import { Hub } from '@getvim/vim-os-sdk-api';
import { Subscription } from './subscription';

export class AppStateApi extends Subscription<Hub.AppStateSubscription> implements Hub.AppState {
  constructor(incomingOsMessagePort: MessagePort) {
    super({});
    this.isAppOpen = false;
    incomingOsMessagePort.addEventListener(
      'message',
      (message: OSToAppMessageEvent<{ withPII: true }>) => {
        if (message?.data?.type === OSToAppMessageTypes.APP_STATE) {
          const { isAppOpen, appOpenTrigger, appCloseTrigger } = message.data.payload;
          if (this.isAppOpen !== isAppOpen) {
            this.isAppOpen = isAppOpen;
            this.dispatch('isAppOpen', this.isAppOpen);
            this.dispatch('appOpenStatus', {
              isAppOpen: this.isAppOpen,
              appOpenTrigger: isAppOpen ? appOpenTrigger : undefined,
              appCloseTrigger: isAppOpen ? undefined : appCloseTrigger,
            });
          }
          if (this.appSessionId !== message.data.payload.appSessionId) {
            this.appSessionId = message.data.payload.appSessionId;
            this.dispatch('appSessionId', this.appSessionId);
          }
        }
      },
    );
  }

  isAppOpen?: boolean | undefined;
  appSessionId?: string | undefined;
}
