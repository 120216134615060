import { useContext } from 'react';
import {
  VimSDKApiContext,
  VimSDKResourcesContext,
  VimSdkAuthContext,
  VimSdkUserMetadataContext,
  VimSDKAppStateContext,
  VimSdkSharedServiceStateContext,
  AppResourceContextWithPII,
} from './store';

export const useAppState = () => {
  return useContext(VimSDKAppStateContext);
};

export const useResources = () => {
  return useContext(VimSDKResourcesContext);
};

export const useResourcesWithPII = () => {
  return useContext<AppResourceContextWithPII>(VimSDKResourcesContext as any);
};

export const useApi = () => {
  return useContext(VimSDKApiContext);
};

export const useVimAuth = () => {
  return useContext(VimSdkAuthContext);
};

export const useVimUserMetadata = () => {
  return useContext(VimSdkUserMetadataContext);
};

export const useSharedServiceState = () => {
  return useContext(VimSdkSharedServiceStateContext);
};
