import { gql } from '@apollo/client';
import { CdeGqlClientManager } from './gqlClient';

export interface FileDetailsInput {
  name: string;
  extension: string;
}

export interface UploadSpecsInput {
  jobUUID: string;
  files: FileDetailsInput[];
}

export interface UploadFileSpecResponse {
  path: string;
  presignedKey: string;
}

const GET_UPLOAD_SPECS_MUTATION = gql`
  mutation getUploadSpecs($input: UploadSpecsInput!) {
    getUploadSpecs(input: $input) {
      path
      presignedKey
    }
  }
`;

export async function getUploadSpecs(
  input: UploadSpecsInput,
): Promise<UploadFileSpecResponse[] | undefined> {
  const gqlClient = await CdeGqlClientManager.getClient();

  const result = await gqlClient.mutate<
    { getUploadSpecs: UploadFileSpecResponse[] },
    { input: UploadSpecsInput }
  >({
    mutation: GET_UPLOAD_SPECS_MUTATION,
    variables: { input },
    fetchPolicy: 'no-cache',
  });
  return result.data?.getUploadSpecs;
}
