import { Logger } from '@getvim-os/logger';
import {
  AppToOSMessageTypes,
  EHRResource,
  UpdatableEhrFields,
  UpdatableEhrState,
  WRITEBACK_TIMEOUT,
} from '@getvim/vim-os-api';
import { EHR, ErrorMessageResponseType } from '@getvim/vim-os-sdk-api';
import { OsCommunicator } from '..';
import { IResourceUpdateBuilder } from '../../types/ehr-updates/builders';

const getEhrStateKeys = (ehrState: {
  [resource in EHRResource]?: any;
}) => {
  return Object.values(EHRResource).reduce((acc, resource) => {
    if (!Object.keys(ehrState).includes(resource)) {
      return acc;
    }
    return {
      ...acc,
      [resource]:
        ehrState[resource] &&
        Object.keys(ehrState[resource])?.filter((key) => ehrState[resource][key] !== undefined),
    };
  }, {});
};

type RUpdatableFields = Required<UpdatableEhrFields>;

export default abstract class ResourceUpdateBuilder<T extends EHRResource>
  implements IResourceUpdateBuilder
{
  protected abstract readonly ehrResource: T;
  #updatableFields: RUpdatableFields[T] | undefined = undefined;
  #ehrUpdatableFields: RUpdatableFields[T] | undefined = undefined;
  #ehrState: EHR.EHR_STATE_RESOURCES<any>;
  #dataToUpdate: UpdatableEhrState<T> = {};
  #dataToAdd: UpdatableEhrState<T> = {};

  #blockedByAppSupport: boolean = false;
  #blockedFields: string[] = [];
  #blockedByEhrSupport: boolean = false;

  constructor(
    private osCommunicator: OsCommunicator,
    updatableFields: RUpdatableFields[T],
    ehrUpdatableFields: RUpdatableFields[T],
    ehrState: EHR.EHR_STATE_RESOURCES<any>,
    private skipUpdateValidation?: boolean,
    private logger?: Logger,
  ) {
    this.#updatableFields = updatableFields;
    this.#ehrUpdatableFields = ehrUpdatableFields;
    this.#ehrState = ehrState;
  }

  protected setObjectField<
    F extends string & keyof RUpdatableFields[T] & keyof UpdatableEhrState<T>,
  >(field: F, value: UpdatableEhrState<T>[F]) {
    const noNilValue =
      value &&
      Object.keys(value).reduce((acc, key) => {
        if (value[key] !== undefined && value[key] !== null) {
          acc[key] = value[key];
        }
        return acc;
      }, {});
    const ehrSupportsUpdate =
      noNilValue &&
      Object.keys(noNilValue).every((key) => this.#ehrUpdatableFields?.[field]?.[key]);
    const appSupportsUpdate =
      noNilValue && Object.keys(noNilValue).every((key) => this.#updatableFields?.[field]?.[key]);

    if (appSupportsUpdate || this.skipUpdateValidation) {
      this.#dataToUpdate[field] = noNilValue;
    } else {
      this.#blockedFields.push(field);
      this.#blockedByAppSupport = true;
      this.#blockedByEhrSupport = this.#blockedByEhrSupport || !ehrSupportsUpdate;
    }
    return this;
  }
  protected setField<F extends string & keyof RUpdatableFields[T] & keyof UpdatableEhrState<T>>(
    field: F,
    value: UpdatableEhrState<T>[F],
  ) {
    const ehrSupportsUpdate = this.#ehrUpdatableFields?.[field] === true;
    const appSupportsUpdate = this.#updatableFields?.[field] === true;

    if (appSupportsUpdate || this.skipUpdateValidation) {
      this.#dataToUpdate[field] = value;
    } else {
      this.#blockedFields.push(field);
      this.#blockedByAppSupport = true;
      this.#blockedByEhrSupport = this.#blockedByEhrSupport || !ehrSupportsUpdate;
    }
    return this;
  }
  protected appendField<F extends string & keyof RUpdatableFields[T] & keyof UpdatableEhrState<T>>(
    field: F,
    value: UpdatableEhrState<T>[F],
  ) {
    const ehrSupportsUpdate = this.#ehrUpdatableFields?.[field] === true;
    const appSupportsUpdate = this.#updatableFields?.[field] === true;

    if (appSupportsUpdate || this.skipUpdateValidation) {
      this.#dataToAdd[field] = value;
    } else {
      this.#blockedFields.push(field);
      this.#blockedByAppSupport = true;
      this.#blockedByEhrSupport = this.#blockedByEhrSupport || !ehrSupportsUpdate;
    }
    return this;
  }

  protected getField<F extends keyof UpdatableEhrState<T>>(field: F): UpdatableEhrState<T>[F] {
    return this.#dataToUpdate[field];
  }

  public async commit() {
    try {
      if (
        Object.keys(this.#dataToUpdate).length === 0 &&
        Object.keys(this.#dataToAdd).length === 0
      ) {
        if (this.#blockedByEhrSupport) {
          this.logger?.error("Application couldn't update anything due to lack of EHR support", {
            resource: this.ehrResource,
            blockedFields: this.#blockedFields,
            ehrUpdatableFields: this.#ehrUpdatableFields,
            ehrState: getEhrStateKeys(this.#ehrState),
          });
          throw {
            type: ErrorMessageResponseType.validation_error,
            data: `The update of ${this.ehrResource} failed due to preconditions. The EHR system does not support the fields you are trying to update.`,
          };
        } else if (this.#blockedByAppSupport) {
          this.logger?.error("Application couldn't update anything due to lack of App support", {
            resource: this.ehrResource,
            blockedFields: this.#blockedFields,
            updatableFields: this.#updatableFields,
            ehrState: getEhrStateKeys(this.#ehrState),
          });
          throw {
            type: ErrorMessageResponseType.authorization_error,
            data: `The update of ${this.ehrResource} failed due to insufficient permissions. Your application does not have the correct write permissions.`,
          };
        } else {
          this.logger?.error('No fields were specified in the update request', {
            resource: this.ehrResource,
          });
          throw {
            type: ErrorMessageResponseType.validation_error,
            data: `The update of ${this.ehrResource} failed due to preconditions. No fields were specified in the update request.`,
          };
        }
      }

      const res = await this.osCommunicator.sendAwaitedMessage(
        {
          type: AppToOSMessageTypes.UPDATE_RESOURCE,
          payload: {
            resource: this.ehrResource,
            dataToUpdate: this.#dataToUpdate,
            dataToAdd: this.#dataToAdd,
          },
        },
        WRITEBACK_TIMEOUT,
      );
      if (res && typeof res === 'object' && Object.values(res).includes(false)) {
        this.logger?.error('Update request returned some false fields', {
          resource: this.ehrResource,
          dataToUpdate: this.#dataToUpdate,
          dataToAdd: this.#dataToAdd,
          res,
        });
        throw {
          type: ErrorMessageResponseType.internal_error,
          data: {
            reason: 'Some fields failed to update',
            updateResult: res,
          },
        };
      }
      return res;
    } finally {
      this.#dataToAdd = {};
      this.#dataToUpdate = {};
      this.#blockedByAppSupport = false;
      this.#blockedFields = [];
      this.#blockedByEhrSupport = false;
    }
  }
}
