import React, { useCallback } from 'react';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { StyledTable, StyledTableCell, ToReviewBeforeTableHeader } from './StyledComponents';
import { PatientRequestsTableProps } from '../../types/table';
import { useAppState } from '../../stores/AppState.store';
import { AppStateActionType } from '../../stores/appState.types';
import { OpenRequestsTableRow } from './OpenRequestsTableRow';

import './Tables.less';

const OpenRequestsTable: React.FC<PatientRequestsTableProps> = ({ rows, handleActionClick }) => {
  const {
    dispatch,
    state: { rejectedRows },
  } = useAppState();

  const setRejectedRow = useCallback(
    (requestId: string) => {
      dispatch({
        type: AppStateActionType.SET_REJECTED_ROWS,
        payload: [...rejectedRows, requestId],
      });
      dispatch({ type: AppStateActionType.DECREASE_OPEN_REQUESTS_TOTAL });
    },
    [dispatch, rejectedRows],
  );

  return (
    <StyledTable className="cde-table" stickyHeader>
      <TableHead className="cde-table-thead open-requests">
        <TableRow>
          <StyledTableCell width="160px">Patient name</StyledTableCell>
          <StyledTableCell width="145px">Patient DOB</StyledTableCell>
          <StyledTableCell width="130px">Data requester</StyledTableCell>
          <StyledTableCell width="110px">Chart request{<br />}range</StyledTableCell>
          <StyledTableCell>
            <ToReviewBeforeTableHeader reviewBefore={rows[0].reviewBefore} />
          </StyledTableCell>
          <StyledTableCell width="65px">Action</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => {
          return (
            <OpenRequestsTableRow
              row={row}
              handleActionClick={handleActionClick}
              setRejectedRow={setRejectedRow}
              rejectedRows={rejectedRows}
              index={index}
              key={row.patientRequestId}
            />
          );
        })}
      </TableBody>
    </StyledTable>
  );
};

export default OpenRequestsTable;
