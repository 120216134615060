import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { ButtonStyle } from '@getvim/internal-vim-os-sdk/types';
import { useCallback } from 'react';
import dayjs from 'dayjs';
import { ExtendedPatientRequestsDetailsResponse } from '../api/bff';
import { formatToLocalDate } from '../formatters';
import { sessionIdManager } from '../utils/sessionIdManager';

export const getNotificationMessage = (date: string): string => {
  let messageDay = formatToLocalDate(date, 'MM/DD/YYYY');

  if (messageDay === dayjs().format('MM/DD/YYYY')) {
    messageDay = 'today';
  } else if (messageDay === dayjs().add(1, 'day').format('MM/DD/YYYY')) messageDay = 'tomorrow';

  return `<b>You have charts to review.</b> Charts will
  automatically be shared with the requester if 
  they are not rejected <b>by ${messageDay}</b>.`;
};

export const useNotification = () => {
  const { pushNotification } = useApi();

  const notify = useCallback(
    ({
      extendedPatientRequestsDetails,
      paginationResult,
    }: ExtendedPatientRequestsDetailsResponse) => {
      if (!paginationResult?.totalResults || !extendedPatientRequestsDetails.length) return;

      const notificationConfig = {
        text: getNotificationMessage(extendedPatientRequestsDetails[0].toReviewBefore),
        notificationId: sessionIdManager.getId()!,
        timeoutInSec: 20,
        actionButtons: {
          leftButton: {
            text: 'Ok',
            buttonStyle: ButtonStyle.LINK,
            openAppButton: false,
            callback: () => {},
          },
          rightButton: {
            text: 'Review',
            buttonStyle: ButtonStyle.PRIMARY,
            openAppButton: true,
            callback: () => {},
          },
        },
      };

      pushNotification.show(notificationConfig);
    },
    [pushNotification],
  );

  return { notify };
};
