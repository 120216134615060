import { analyticsManager } from './analyticsManager';
import { ActionSubmissionStatusPayload } from './types/cdeAppAnalytics.types';

export const trackActionSubmissionStatus = ({
  data_requester,
  action_type,
  vim_patient_id,
  pseudonymized_patient_id,
  extraction_status,
  extraction_from_date,
  extraction_to_date,
  to_review_before_date,
  approval_status,
  extracted_with_first_name,
  extracted_with_last_name,
  status,
}: ActionSubmissionStatusPayload): void => {
  analyticsManager.track('cde_action_submission_status', {
    app_source_displayed: analyticsManager.getDatasource(),
    data_requester,
    action_type,
    vim_patient_id,
    pseudonymized_patient_id,
    extraction_status,
    extraction_from_date,
    extraction_to_date,
    to_review_before_date,
    approval_status,
    extracted_with_first_name,
    extracted_with_last_name,
    status,
  });
};
