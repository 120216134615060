import { PATIENT_REQUEST_FAILED_DETAILS, PATIENT_REQUEST_STATUS } from '.';

export enum ActionAnalyticsType {
  ACTION_CLICKED = 'action_clicked',
  ACTION_SUBMITTED = 'action_submitted',
  ACTION_SUBMISSION_STATUS = 'action_submission_status',
}

export interface PatientRequestsTableRow {
  patientRequestId: string;
  recordId: string;
  patientName: string;
  patientDateOfBirth: string;
  dataRequester: string;
  chartRequestRange: JSX.Element;
  reviewBefore: string;
  extractedWith: string;
  status: PATIENT_REQUEST_STATUS;
  details: PATIENT_REQUEST_FAILED_DETAILS | undefined;
  sharedAt?: string;
  originalRequester?: string;
  expirationDate?: string;
}

export type PatientRequestsTableProps = {
  rows: PatientRequestsTableRow[];
  handleActionClick: (
    recordId: string,
    actionType: ActionAnalyticsType,
    responseStatus?: boolean,
  ) => void;
};

export type ToReviewBeforeTableHeaderProps = {
  reviewBefore: string;
};

export interface TableActionsBarProps {
  paging: PagingPayload;
  dataUpdatedAt: string;
  refetch?: () => void;
}

export interface PagingPayload {
  totalResults: number;
  setCurrentPage: (page: number) => void;
  currentPage: number;
  isFetching?: boolean;
}

export type AllRequestsTableRowProps = {
  row: PatientRequestsTableRow;
  index: number;
};

export type OpenRequestsTableRowProps = {
  row: PatientRequestsTableRow;
  index: number;
  rejectedRows: string[];
  setRejectedRow: (requestId: string) => void;
  handleActionClick: (
    recordId: string,
    actionType: ActionAnalyticsType,
    responseStatus?: boolean,
  ) => void;
};
