import { FileMetadata } from './file';
import { JobContext } from './state';

export enum ActionName {
  CDEGetPatient = 'CDEGetPatient',
  CDEPatientEncounterList = 'CDEPatientEncounterList',
  CDEGetPatientEncounterDetails = 'CDEGetPatientEncounterDetails',
  CDEGetPatientEncounterMetadataDetails = 'CDEGetPatientEncounterMetadataDetails',
  CDEDisclosureOfInformation = 'CDEDisclosureOfInformation',
}

export enum AffectedEntityType {
  Patient = 'Patient',
}

export interface AuditGetPatientEncountersActionProps {
  success: boolean;
  jobContext: JobContext;
}

export interface AuditFileMetadataWritingToS3 {
  file: Omit<FileMetadata, 'data'>;
  success: boolean;
  jobContext: JobContext;
}

export interface AuditGetPatientAction {
  success: boolean;
  jobContext: JobContext;
}
