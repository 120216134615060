import React, { FC, useCallback } from 'react';
import { ErrorBoundary, FallbackProps } from 'react-error-boundary';
import ErrorFallback from '../error-fallback';

interface ErrorBoundaryWrapperProps {
  onRenderError: (error: { error: any }) => void;
  applicationDisplayName?: string;
  subMessage?: string;
}

const ErrorBoundaryWrapper: FC<ErrorBoundaryWrapperProps> = ({
  onRenderError,
  applicationDisplayName,
  subMessage,
  children,
}) => {
  const fallbackRender = useCallback(
    ({ error, resetErrorBoundary }: FallbackProps) => {
      onRenderError({ error });
      return (
        <ErrorFallback
          buttonText={applicationDisplayName}
          buttonOnClick={resetErrorBoundary}
          subheading={subMessage}
        />
      );
    },
    [applicationDisplayName, onRenderError],
  );
  return <ErrorBoundary fallbackRender={fallbackRender}>{children}</ErrorBoundary>;
};

export default ErrorBoundaryWrapper;
