import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { ActivationStatus } from '@getvim/internal-vim-os-sdk/types';
import { useCallback } from 'react';
import { appLogger } from '../components/App';

export type ModifyAppViewStatePayload = {
  enable: boolean;
  notifications?: number;
};

export interface AppStateCallbacks {
  modifyAppState: (payload: ModifyAppViewStatePayload) => void;
}

export const useModifyAppState = (): AppStateCallbacks => {
  const { setActivationStatus, setNotificationsIndicator, clearNotificationsIndicator } = useApi();
  const modifyAppState = useCallback(
    (payload: ModifyAppViewStatePayload): void => {
      const { enable, notifications } = payload;

      appLogger.debug('Trigger modify app view event', payload);

      setActivationStatus(enable ? ActivationStatus.ENABLED : ActivationStatus.DISABLED);
      if (enable && notifications && notifications > 0) {
        setNotificationsIndicator(0);
      } else {
        clearNotificationsIndicator();
      }
    },
    [setActivationStatus, setNotificationsIndicator, clearNotificationsIndicator],
  );

  return { modifyAppState };
};
