import React from 'react';
import dayjs from 'dayjs';
import {
  PATIENT_REQUEST_FAILED_DETAILS,
  STATUS_CHIP_ENUM,
  PATIENT_REQUEST_STATUS,
} from '../../types';
import { Chip, Text } from '@getvim/atomic-ui';
import { CHIP_DATA } from '../../consts';

interface StatusProps {
  status: PATIENT_REQUEST_STATUS;
  details: PATIENT_REQUEST_FAILED_DETAILS | undefined;
  sharedAt?: string;
  isFirstRow?: boolean;
  expiration_date?: string;
}

function createStatusChip(
  status: STATUS_CHIP_ENUM,
  isFirstRow: boolean | undefined,
  sharedAt = '',
): React.ReactElement<any, any> | null {
  return (
    <div className="status-container">
      <Chip
        text={CHIP_DATA[status].text}
        leftIcon={CHIP_DATA[status].leftIcon}
        bgColor={CHIP_DATA[status].bgColor}
        tooltipText={CHIP_DATA[status].tooltipText}
        tooltipShadow
        bottomTooltip={isFirstRow}
      />
      {sharedAt ? <Text text={`On: ${sharedAt}`} /> : ''}
      {CHIP_DATA[status].info ? <Text text={CHIP_DATA[status].info} /> : ''}
    </div>
  );
}

const StatusElement: React.FC<StatusProps> = ({
  status,
  details,
  sharedAt,
  isFirstRow,
  expiration_date,
}) => {
  const isExpired = dayjs(expiration_date) < dayjs();
  switch (status) {
    case PATIENT_REQUEST_STATUS.REJECTED:
      return createStatusChip(STATUS_CHIP_ENUM.REJECTED, isFirstRow);

    case PATIENT_REQUEST_STATUS.FAILED:
      switch (details) {
        case PATIENT_REQUEST_FAILED_DETAILS.PATIENT_DOESNT_EXIST:
          return createStatusChip(STATUS_CHIP_ENUM.PATIENT_DOESNT_EXIST, isFirstRow);

        case PATIENT_REQUEST_FAILED_DETAILS.DATA_RESTRICTED:
          return createStatusChip(STATUS_CHIP_ENUM.DATA_RESTRICTED, isFirstRow);

        case PATIENT_REQUEST_FAILED_DETAILS.ENCOUNTER_NOT_LOCKED:
          return isExpired
            ? createStatusChip(STATUS_CHIP_ENUM.ENCOUNTER_NOT_LOCKED_EXPIRED, isFirstRow)
            : createStatusChip(STATUS_CHIP_ENUM.ENCOUNTER_NOT_LOCKED_FAILED, isFirstRow);

        default:
          return createStatusChip(STATUS_CHIP_ENUM.FAILED, isFirstRow);
      }

    case PATIENT_REQUEST_STATUS.SHARED:
      return createStatusChip(STATUS_CHIP_ENUM.SHARED, isFirstRow, sharedAt);

    case PATIENT_REQUEST_STATUS.NO_DATA:
      return createStatusChip(STATUS_CHIP_ENUM.NO_DATA, isFirstRow);

    default:
      if (isExpired) {
        return createStatusChip(STATUS_CHIP_ENUM.EXPIRED, isFirstRow);
      }
      return createStatusChip(STATUS_CHIP_ENUM.PROCESSING, isFirstRow);
  }
};

export default StatusElement;
