import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const formatDate = (date: string, format: string) => dayjs(date).format(format);

export const formatToLocalDate = (date: string, format: string) =>
  dayjs(date).local().format(format);

export const formatDateToLocalHours = (date: string) =>
  dayjs(date).local().format('hha').replace(/^0+/, '');
