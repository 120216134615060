import { EHR_EVENT } from '@getvim-os/types';
import { OSToAppMessageEvent, OSToAppMessageTypes } from '@getvim/vim-os-api';
import { InternalSDKToAppMessageTypes } from '../types';
import { VimEventTarget } from './vimEventTarget';
export class EhrEventsApi {
  constructor(incomingOsMessagePort: MessagePort, eventTarget: VimEventTarget) {
    incomingOsMessagePort.addEventListener(
      'message',
      (message: OSToAppMessageEvent<{ withPII: true }>) => {
        switch (message?.data?.type) {
          case OSToAppMessageTypes.ON_EHR_EVENT: {
            const event = new CustomEvent<EHR_EVENT>(InternalSDKToAppMessageTypes.ON_EHR_EVENT, {
              detail: message.data.payload,
            });
            eventTarget.dispatchEvent(event);
            break;
          }
        }
      },
    );
  }
}
