import React from 'react';
import { ColorNameEnum, WeightEnum, SizeEnum, Text } from '@getvim/atomic-ui';
import './TabContentEmpty.less';

const TabContentEmpty: React.FC<object> = () => {
  return (
    <div className="patient-request-empty-container">
      <div>
        <Text
          colorName={ColorNameEnum.theme}
          text="There are no requests processing at this time."
          weight={WeightEnum.bold}
          size={SizeEnum['18px']}
        />
        <Text
          colorName={ColorNameEnum.theme}
          text="Once there are chart requests in process and ready for your review, they will show up here. Please review and reject as needed."
          size={SizeEnum['14px']}
          className="empty-state-title"
        />
      </div>
      <img className="cactusImage" src="https://static.getvim.com/img/cactus.svg" alt="title" />
    </div>
  );
};

export default TabContentEmpty;
