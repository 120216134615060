import { useQuery } from '@tanstack/react-query';
import { MS_SLEEP_BETWEEN_FIND_PATIENT_REQUESTS } from '../../../consts';
import { BffApi } from '../../../api';
import { ExtendedPatientRequestsInput } from '../../../api/bff';
import { useEffect } from 'react';
import { appLogger } from '../../../components/App';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export const useFindExtendedPatientRequestsQuery = ({
  queryKey,
  input,
  isAppOpen,
}: {
  queryKey: string;
  input?: Partial<ExtendedPatientRequestsInput>;
  isAppOpen?: boolean;
}) => {
  const { data, isFetching, refetch, dataUpdatedAt } = useQuery({
    queryKey: [queryKey],
    queryFn: () => BffApi.findExtendedPatientRequests(input),
    refetchOnWindowFocus: false,
    refetchInterval: () => (isAppOpen ? false : MS_SLEEP_BETWEEN_FIND_PATIENT_REQUESTS),
  });

  useEffect(() => {
    if (!isFetching) {
      appLogger.info('CDE app got extended-patient-requests from backend', {
        input,
        data,
      });
    }
  }, [data, input, isFetching]);

  return {
    data,
    isFetching,
    refetch,
    dataUpdatedAt: dayjs(dataUpdatedAt).local().format('HH:mm:ss'),
  };
};
