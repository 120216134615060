export type AppState = {
  rejectedRows: string[];
  openRequestsTotal: number;
  allRequestsTotal: number;
};

export enum AppStateActionType {
  SET_REJECTED_ROWS = 'SET_REJECTED_ROWS',
  SET_OPEN_REQUESTS_TOTAL = 'SET_OPEN_REQUESTS_TOTAL',
  SET_ALL_REQUESTS_TOTAL = 'SET_ALL_REQUESTS_TOTAL',
  DECREASE_OPEN_REQUESTS_TOTAL = 'DECREASE_OPEN_REQUESTS_TOTAL',
  RESET_STATE = 'RESET_STATE',
}

export type AppStateAction =
  | { type: AppStateActionType.DECREASE_OPEN_REQUESTS_TOTAL }
  | { type: AppStateActionType.SET_OPEN_REQUESTS_TOTAL; payload: number | undefined }
  | { type: AppStateActionType.SET_ALL_REQUESTS_TOTAL; payload: number | undefined }
  | { type: AppStateActionType.SET_REJECTED_ROWS; payload: string[] }
  | { type: AppStateActionType.RESET_STATE };
