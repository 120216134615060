import type { Diagnosis } from '../../../schemas/ehr/ehr-state/diagnosis.mjs';

export enum PatientEnhancements {
  GET_PROBLEM_LIST = 'getProblemList',
}

export type PatientEnhancementsAPI = {
  getProblemList(): Promise<Diagnosis[] | undefined>;
};

/**
 * THIS Types below are used to verify that the PatientEnhancementsAPI
 * uses all the properties from the enum & the other way around
 *
 * The reason is using mapped types "[PatientEnhancements.GET_PROBLEM_LIST]()" instead of "getProblemList()"
 * creates bad autocomplete typings
 */
type KeysOfAPI = {
  [x in keyof PatientEnhancementsAPI]: any;
};
type KeysOfEnum = {
  [x in PatientEnhancements]: any;
};
const enumObj: KeysOfEnum = {} as any;
const apiObj: KeysOfAPI = {} as any;

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const assertOneWay = enumObj satisfies KeysOfAPI;
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const assertOtherWay = apiObj satisfies KeysOfEnum;
