import React from 'react';
import classNames from 'classnames';
import { ColorNameEnum, SizeEnum } from '../../types';
import Text from '../text';
import './index.less';

export type ProgressBarProps = {
  className?: string;
  label?: string;
  duration?: string;
  animate?: boolean;
  value?: number;
  showLabel?: boolean;
  progressBarStyle?: keyof typeof progressBarStyleOptions;
  width?: keyof typeof progressBarWidthOptions;
  maxValueLabel?: string;
  valueLabel?: string;
  color?: keyof typeof progressBarColorOptions;
};

const progressBarStyleOptions = {
  thin: 'thin-style',
  tiny: 'tiny-style',
};

const progressBarWidthOptions = {
  small: 'small-width',
};

const progressBarColorOptions = {
  main: 'main-color',
};

const ProgressBar: React.FC<ProgressBarProps> = ({
  className,
  value,
  progressBarStyle,
  width,
  label = 'Loading',
  showLabel = false,
  animate = false,
  duration,
  maxValueLabel,
  valueLabel,
  color = 'main',
}) => (
  <div
    className={classNames(
      'atomic-progress-bar-wrap',
      className,
      progressBarWidthOptions[width!],
      progressBarColorOptions[color!],
      {
        animate,
      },
    )}
  >
    {valueLabel && maxValueLabel && (
      <div className="atomic-progress-data-labels">
        <Text
          className="value-label"
          size={SizeEnum['12px']}
          colorName={ColorNameEnum.ultraDark}
          text={valueLabel}
        />
        <Text
          className="max-value-label"
          size={SizeEnum['12px']}
          colorName={ColorNameEnum.ultraDark}
          text={`of ${maxValueLabel}`}
        />
      </div>
    )}
    <div className={classNames('atomic-progress-bar', progressBarStyleOptions[progressBarStyle!])}>
      <div
        className="atomic-progress"
        style={{ width: `${value}%`, animationDuration: duration }}
      />
    </div>
    {showLabel && (
      <Text size={SizeEnum['14px']} className="atomic-progress-bar-label">
        {label} ({value}%)
      </Text>
    )}
  </div>
);

export default ProgressBar;
