import { gql } from '@apollo/client';
import { CdeGqlClientManager } from './gqlClient';
import { appLogger } from '../../components/App';

export enum RejectFinishStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export interface RejectPatientRequestsInput {
  patientRequestIds: number[];
}

const REJECT_PATIENT_REQUESTS_MUTATION = gql`
  mutation rejectPatientRequests($input: RejectPatientRequestsInput!) {
    rejectPatientRequests(input: $input)
  }
`;

export async function rejectPatientRequests(input: RejectPatientRequestsInput): Promise<boolean> {
  try {
    const gqlClient = await CdeGqlClientManager.getClient();

    await gqlClient.mutate<object, { input: RejectPatientRequestsInput }>({
      mutation: REJECT_PATIENT_REQUESTS_MUTATION,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return true;
  } catch (error: any) {
    const logData = { input, error };

    if (error.status === 409) {
      appLogger.warning('Failed to reject patient request is not registered under worker', logData);
    }

    appLogger.error('Failed to reject patient request', logData);
    return false;
  }
}
