import React from 'react';
import './index.less';
import errorLogo from '../../../assets/images/error-logo.svg';
import { Button, ColorNameEnum, SizeEnum, Text, WeightEnum } from '../..';

interface ErrorFallbackProps {
  title?: string;
  subtitle?: string;
  subheading?: string;
  buttonText?: string;
  buttonOnClick?: () => void;
}

const ErrorFallback = ({
  title = 'Oops',
  subtitle = 'Something went wrong.',
  subheading = 'We could not properly load the app.',
  buttonText,
  buttonOnClick,
}: ErrorFallbackProps) => {
  return (
    <div className="error-fallback-container">
      <img src={errorLogo} />
      <div className="error-titles-container">
        <Text
          size={SizeEnum['22px']}
          colorName={ColorNameEnum.ultraDark}
          text={title}
          weight={WeightEnum['semibold']}
        />
        <Text
          size={SizeEnum['18px']}
          weight={WeightEnum['light']}
          colorName={ColorNameEnum.ultraDark}
          text={subtitle}
        />
        <Text
          colorName={ColorNameEnum.ultraDark}
          weight={WeightEnum['light']}
          size={SizeEnum['18px']}
          text={subheading}
        />
        <div className="action-button-container">
          <Button
            text={`Reload ${buttonText}` || 'Reload Application'}
            onClick={buttonOnClick}
            buttonType="small"
            width="small"
            bgColor="default"
          />
        </div>
      </div>
    </div>
  );
};

export default ErrorFallback;
