import { ScopedLogger } from '@getvim/vim-connect-logger';
import { JobDetails } from '../../types';
import { JobFinishStatus } from '../../api/bff';

export const logCDERequestsReport = ({
  job,
  jobLogger,
  status,
}: {
  job: JobDetails;
  jobLogger: ScopedLogger;
  status: JobFinishStatus;
}) => {
  if (job) {
    jobLogger.info('CDE request report', {
      chartId: job.patient.clientInternalRecordId,
      patientName: `${job.patient.firstName} ${job.patient.lastName}`,
      extractionDate: new Date().toISOString(),
      dataRequester: job.requester,
      batchOperationId: job.batchOperationId,
      status,
      encounterRangeStartDate: job.fromDate,
      encounterRangeEndDate: job.untilDate,
      encounterId: job.encounterId,
    });
  }
};
