import { VimSDKProvider } from '@getvim/internal-vim-os-sdk/react';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClientProvider } from '@tanstack/react-query';
import { ThemeVariablesWrapper, themes } from '@getvim/components-hooks-use-theme';
import { FeatureFlagProvider } from '@getvim/feature-flags-react';
import { ErrorBoundaryWrapper, Loader } from '@getvim/atomic-ui';
import { featureFlagsClient, queryClient } from './services';
import { initWorker } from './logic/init-worker';
import AsyncContentWrapper from './AsyncContentWrapper';
import { getLogger } from './components/logger';
import { App } from './components/App';
import './index.less';
export const indexLogger = getLogger({ scope: 'CDE App index' });

const handleRenderError = ({ error }) =>
  indexLogger.error('Client application crashed due to render error', {
    error,
  });

createRoot(document.getElementById('root')).render(
  <VimSDKProvider>
    <QueryClientProvider client={queryClient}>
      <FeatureFlagProvider featureFlagsClient={featureFlagsClient}>
        <ThemeVariablesWrapper theme={themes.vim}>
          <AsyncContentWrapper promises={[initWorker()]} loader={<Loader type="dots" />}>
            <ErrorBoundaryWrapper
              onRenderError={handleRenderError}
              applicationDisplayName="Clinical Data Exchange"
            >
              <React.StrictMode>
                <App />
              </React.StrictMode>
            </ErrorBoundaryWrapper>
          </AsyncContentWrapper>
        </ThemeVariablesWrapper>
      </FeatureFlagProvider>
    </QueryClientProvider>
  </VimSDKProvider>,
);
