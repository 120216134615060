import { VimOsLogger, getStandAloneLogger } from '@getvim-os/logger';

let singletonLogger: VimOsLogger | undefined;

export const getLogger = (): VimOsLogger => {
  if (singletonLogger) {
    return singletonLogger;
  }
  singletonLogger = getStandAloneLogger(`VimSDK ${window.location.host}`, {
    hostname: window.location.host,
  });
  return singletonLogger;
};
