import { injectable } from 'inversify';
import { LoggerTags, LogMetadata } from '../types';

/**
 * Class that holds config in a global level
 */
@injectable()
export class LoggerGlobalConfig {
  #metadata?: LogMetadata;

  #accessToken?: string;

  #remoteLogSupported = true;

  #limiterSupported = true;

  #staticTags: LoggerTags = {};

  public moduleName?: string;

  get accessToken() {
    return this.#accessToken;
  }
  get remoteLogSupported() {
    return this.#remoteLogSupported;
  }
  get limiterSupported() {
    return this.#limiterSupported;
  }
  get staticTags() {
    return this.#staticTags;
  }

  get metadata() {
    return this.#metadata;
  }

  public setMetadata(metadata: LogMetadata) {
    this.#metadata = {
      ...metadata,
      adapterName: metadata.adapterName?.toLowerCase(),
    };
  }
  public updateMetadata(paramsToUpdate: Partial<LogMetadata>) {
    this.#metadata = {
      ...this.#metadata!,
      ...paramsToUpdate,
    };
    if (this.#metadata.adapterName)
      this.#metadata.adapterName = this.#metadata.adapterName.toLowerCase();
  }

  public setDeviceId(deviceId) {
    this.#metadata = {
      version: 'unknown',
      windowId: 'unknown',
      ...this.#metadata,
      deviceId,
    };
  }

  public setStaticTags(tags: LoggerTags) {
    this.#staticTags = { ...this.#staticTags, ...tags };
  }

  public setAccessToken(accessToken: string) {
    this.#accessToken = accessToken;
  }

  public setRemoteLogSupport(supported: boolean) {
    this.#remoteLogSupported = supported;
  }

  public setLimiterSupport(supported: boolean) {
    this.#limiterSupported = supported;
  }
}
