import React, { createContext, useContext, useReducer } from 'react';
import { AppState, AppStateAction, AppStateActionType } from './appState.types';

const initialState: AppState = {
  rejectedRows: [],
  openRequestsTotal: 0,
  allRequestsTotal: 0,
};

const AppStateContext = createContext<{
  state: AppState;
  dispatch: React.Dispatch<AppStateAction>;
}>({
  state: initialState,
  dispatch: () => {},
});

export const useAppState = () => useContext(AppStateContext);

const reducer = (state: AppState, action: AppStateAction): AppState => {
  switch (action.type) {
    case AppStateActionType.DECREASE_OPEN_REQUESTS_TOTAL:
      return { ...state, openRequestsTotal: Math.max(state.openRequestsTotal - 1, 0) };
    case AppStateActionType.SET_OPEN_REQUESTS_TOTAL:
      return { ...state, openRequestsTotal: action.payload || 0 };
    case AppStateActionType.SET_ALL_REQUESTS_TOTAL:
      return { ...state, allRequestsTotal: action.payload || 0 };
    case AppStateActionType.SET_REJECTED_ROWS:
      return { ...state, rejectedRows: action.payload };
    case AppStateActionType.RESET_STATE:
      return initialState;
    default:
      return state;
  }
};

export const AppStateProvider: React.FC = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <AppStateContext.Provider value={{ state, dispatch }}>{children}</AppStateContext.Provider>
  );
};
