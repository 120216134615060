import { ConsoleDisplayOptions, LogLevel, ScopedLogger } from '@getvim/vim-connect-logger';
import { pickBy } from 'lodash-es';
import { jobManager } from '../../state';

interface ContextedLoggerProps {
  scope: string;
  options: ConsoleDisplayOptions;
}

export class ContextedLogger extends ScopedLogger {
  constructor({ scope, options }: ContextedLoggerProps) {
    super(scope, options);
  }

  private getContext() {
    const { jobUUID, batchOperationId, vimPatientId } = jobManager.getAll();

    // remove falsy values
    return pickBy({ jobUUID, batchOperationId, vimPatientId });
  }

  public debug(message: string, data?: any): void {
    this.log(LogLevel.DEBUG, message, { ...this.getContext(), ...data });
  }

  public info(message: string, data?: any): void {
    this.log(LogLevel.INFO, message, { ...this.getContext(), ...data });
  }

  public warning(message: string, data?: any): void {
    this.log(LogLevel.WARNING, message, { ...this.getContext(), ...data });
  }

  public error(message: string, data?: any): void {
    this.log(LogLevel.ERROR, message, { ...this.getContext(), ...data });
  }
}
