import structuredClone from '@ungap/structured-clone';

export class StateManager<TContext extends Record<string, any>> {
  private context: TContext = {} as TContext;
  private subscribers: ((clonedContext: TContext) => void)[] = [];

  public set(newContext: Partial<TContext>) {
    this.context = { ...this.context, ...newContext };
    this.notifySubscribers();
  }

  public get<K extends keyof TContext>(key: K): TContext[K] {
    // Return a copy of the context to avoid direct mutation
    return structuredClone(this.context[key]);
  }

  public getAll(): TContext {
    // Return a copy of the context to avoid direct mutation
    return structuredClone(this.context);
  }

  public clear() {
    this.context = {} as TContext;
  }

  public clearAndNotify() {
    this.clear();
    this.notifySubscribers();
  }

  public subscribe(callback: (clonedContext: TContext) => void) {
    this.subscribers.push(callback);
  }

  private notifySubscribers() {
    const clonedContext = this.getAll();
    for (const subscriber of this.subscribers) {
      subscriber(clonedContext);
    }
  }
}
