export enum PATIENT_REQUEST_STATUS {
  SUCCESS = 'success',
  FAILED = 'failed',
  CREATED = 'created',
  REJECTED = 'rejected',
  STARTED = 'started',
  SHARED = 'shared',
  NO_DATA = 'no_data',
  EXPIRED = 'expired',
  SHARING_FAILED = 'sharing_failed',
}

export enum APPROVAL_STATUS {
  PENDING = 'pending',
  REJECTED = 'rejected',
  AUTO_APPROVED = 'auto_approved',
}

export enum ORDER_BY {
  ASC = 'ASC',
  DESC = 'DESC',
}

export enum SORT_BY {
  UPDATE_TIME = 'updateTime',
  REVIEW_BEFORE = 'reviewBefore',
}

export enum PATIENT_REQUEST_FAILED_DETAILS {
  PATIENT_DOESNT_EXIST = 'patient_doesnt_exist',
  DATA_RESTRICTED = 'data_restricted',
  REVIVE_EXCEED = 'revive_exceed',
  CREATE_PDF_FAILED = 'create_pdf_failed',
  EXPIRED = 'request_time_exceeded',
  ENCOUNTERS_INVALID = 'encounters_invalid',
  ENCOUNTER_NOT_LOCKED = 'encounter_not_locked',
}
