import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ButtonGroupComponent } from '@getvim/atomic-ui';
import { Tab } from '../../types';
import { appLogger } from '../App';
import AllRequestsTab from './AllRequestsTab';
import OpenRequestsTab from './OpenRequestsTab';
import './Tabs.less';
import {
  UseFindExtendedPatientRequestsResponse,
  useGetPatientRequestsData,
} from '../../hooks/api/patient-request/useGetPatientRequestsData';
import { useAppState } from '../../stores/AppState.store';
import { AppStateActionType } from '../../stores/appState.types';
import { useNotification } from '../../hooks/useNotification';
import { trackAppEnabled } from '../../analytics/trackAppEnabled';
import { trackTabSwitch } from '../../analytics/trackTabSwitch';
import { ModifyAppViewStatePayload, useModifyAppState } from '../../hooks/useModifyAppState';

const TabContext: Record<Tab, React.FC<UseFindExtendedPatientRequestsResponse>> = {
  [Tab.OpenRequestsTab]: OpenRequestsTab,
  [Tab.AllRequestsTab]: AllRequestsTab,
};

const Tabs = ({
  userRoles,
  shouldConsiderCdeUserRole,
}: {
  userRoles: string[];
  shouldConsiderCdeUserRole: boolean;
}) => {
  const {
    dispatch,
    state: { openRequestsTotal, allRequestsTotal },
  } = useAppState();
  const [currentTab, setCurrentTab] = useState<Tab>(Tab.OpenRequestsTab);
  const [appEnabled, setAppEnabled] = useState<boolean>(false);

  const { openRequests, allRequests } = useGetPatientRequestsData();

  const { notify } = useNotification();

  const { modifyAppState } = useModifyAppState();

  // should revisit this check as it happens when patient requests are stuck in status Created and not Started and only those SAM-51875
  const openRequestsTotalModified = useMemo(
    () =>
      openRequests.data?.extendedPatientRequestsDetails?.length
        ? openRequests.data?.paginationResult.totalResults
        : 0,
    [
      openRequests.data?.extendedPatientRequestsDetails?.length,
      openRequests.data?.paginationResult.totalResults,
    ],
  );

  useEffect(() => {
    dispatch({
      type: AppStateActionType.SET_OPEN_REQUESTS_TOTAL,
      payload: openRequestsTotalModified,
    });
    dispatch({
      type: AppStateActionType.SET_ALL_REQUESTS_TOTAL,
      payload: allRequests.data?.paginationResult.totalResults,
    });
  }, [dispatch, allRequests.data?.paginationResult.totalResults, openRequestsTotalModified]);

  useEffect(() => {
    const totalResults =
      (openRequests.data?.extendedPatientRequestsDetails?.length ?? 0) +
      (allRequests.data?.extendedPatientRequestsDetails?.length ?? 0);
    const shouldEnableAppOnHub: boolean = totalResults > 0;

    const appState: ModifyAppViewStatePayload = {
      enable: shouldEnableAppOnHub,
      ...(shouldEnableAppOnHub && { notifications: openRequestsTotal }),
    };
    modifyAppState(appState);
    appLogger.debug('modified hub app state', { appState });

    if (shouldEnableAppOnHub && openRequests?.data) {
      notify(openRequests.data);
    }
  }, [modifyAppState, openRequestsTotal, notify, openRequests?.data, allRequests?.data]);

  const applicationVersion = useMemo(
    () => (shouldConsiderCdeUserRole ? 2 : 1),
    [shouldConsiderCdeUserRole],
  );

  useEffect(() => {
    if (!appEnabled && openRequests?.data && allRequests?.data) {
      trackAppEnabled({
        requests: { openRequests: openRequests.data, allRequests: allRequests.data },
        userRoles,
        applicationVersion,
      });
      setAppEnabled(true);
    }
  }, [openRequests?.data, allRequests?.data, userRoles, applicationVersion, appEnabled]);

  const handleTabChange = useCallback(
    (tab: Tab) => {
      appLogger.debug('CDE tab clicked', { tab });
      setCurrentTab(tab);

      if (openRequests?.data && allRequests?.data) {
        trackTabSwitch({
          requests: { openRequests: openRequests.data, allRequests: allRequests.data },
          redirectedFrom: currentTab,
          tabName: tab,
        });
      }
    },
    [allRequests.data, currentTab, openRequests.data],
  );

  const getTabContent = () => (currentTab === Tab.OpenRequestsTab ? openRequests : allRequests);
  const TabComponent = TabContext[currentTab];

  return (
    <>
      <div className="tabs-wrap">
        <ButtonGroupComponent
          className="tabs"
          items={[
            {
              text: `${Tab.OpenRequestsTab} (${openRequestsTotal ?? 0})`,
              value: Tab.OpenRequestsTab,
            },
            {
              text: `${Tab.AllRequestsTab} (${allRequestsTotal ?? 0})`,
              value: Tab.AllRequestsTab,
            },
          ]}
          value={currentTab}
          buttonType="tiny"
          bgColor="groupOptionOutline"
          direction="row"
          onChange={handleTabChange}
          noGaps
        />
      </div>
      <TabComponent {...getTabContent()} />
    </>
  );
};

export default Tabs;
