import { OSEhrState, OSEhrStateWithPII } from '@getvim/vim-os-api';
import { AppEhrState, PatientWithPII } from '../types/ehrState';
import {
  EhrResourceEnhancementsExecutor,
  OSToAppEhrConvertor,
  OsToExternalAppEhrConvertor,
} from '@getvim/dynamic-vim-os-sdk/sdk';

type EHR_STATE = AppEhrState<{ withPII: true }>;

export class OsToInternalAppEhrConvertor implements OSToAppEhrConvertor<EHR_STATE> {
  getProblemList = async (
    vimPatientId: string | undefined,
    ehrResourceEnhancementsExecutor: EhrResourceEnhancementsExecutor<EHR_STATE>,
  ) => {
    if (!vimPatientId) {
      throw new Error('vimPatientId is undefined! unable to call getProblemList');
    }
    return await ehrResourceEnhancementsExecutor.getProblemList(vimPatientId);
  };

  convertPatientWithPII = (
    osPatient: NonNullable<OSEhrStateWithPII['patient']>,
    ehrResourceEnhancementsExecutor: EhrResourceEnhancementsExecutor<EHR_STATE>,
  ): PatientWithPII => {
    const { patientSessionId, token, contentSupplierIdentifiers } = osPatient;
    const externalPatient = this.externalConvertor.convertPatientWithPII(
      osPatient,
      ehrResourceEnhancementsExecutor,
    );
    const appPatient: PatientWithPII = {
      ...externalPatient,
      token,
      patientSessionId,
      contentSupplierIdentifiers,
      getProblemList: () =>
        this.getProblemList(
          externalPatient.identifiers?.vimPatientId,
          ehrResourceEnhancementsExecutor,
        ),
    };
    if (appPatient.insurance) {
      appPatient.insurance.insurer = osPatient.insurance?.insurer;
    }
    return appPatient;
  };

  convertReferralWithPII = (
    osReferral: NonNullable<OSEhrStateWithPII['referral']>,
  ): NonNullable<EHR_STATE['referral']> => {
    const { token } = osReferral;
    const externalReferral = this.externalConvertor.convertReferralWithPII(osReferral);
    return {
      ...externalReferral,
      token,
    };
  };

  convertEncounter = (
    osEncounter: NonNullable<OSEhrState['encounter']>,
  ): NonNullable<EHR_STATE['encounter']> => {
    return this.externalConvertor.convertEncounter(osEncounter);
  };

  convertOrders = (
    osOrders: NonNullable<OSEhrState['orders']>,
  ): NonNullable<EHR_STATE['orders']> => {
    return this.externalConvertor.convertOrders(osOrders);
  };

  constructor() {
    this.externalConvertor = new OsToExternalAppEhrConvertor();
  }
  convertEhr: OSToAppEhrConvertor<EHR_STATE>['convertEhr'] = (
    { patient, encounter, referral, orders }: OSEhrStateWithPII,
    ehrResourceEnhancementsExecutor,
  ) => {
    return {
      patient: patient && this.convertPatientWithPII(patient, ehrResourceEnhancementsExecutor),
      referral: referral && this.convertReferralWithPII(referral),
      encounter: encounter && this.convertEncounter(encounter),
      orders: orders && this.convertOrders(orders),
    };
  };

  private externalConvertor: OsToExternalAppEhrConvertor;
}
