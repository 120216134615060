import React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { Tooltip } from '@getvim/atomic-ui';
import './StyledComponents.less';
import { formatDateToLocalHours } from '../../formatters';
import { ToReviewBeforeTableHeaderProps } from '../../types/table';

export const StyledTable = styled(Table)(() => ({
  borderCollapse: 'separate',
  borderSpacing: '0px 10px',
  fontSize: '12px',
  tr: {
    whiteSpace: 'nowrap',
  },
  cursor: 'default',
}));

export const StyledTableCell = styled(TableCell)(() => ({
  [`&.${tableCellClasses.body}`]: {
    fontSize: 12,
  },
  border: 'none',
  fontFamily: 'inherit',
  paddingLeft: 0,
}));

export const StyledTableRow = styled(TableRow)(() => ({
  'td, th': {
    backgroundColor: '#fff',
    margin: '20px',
    whiteSpace: 'nowrap',
    fontSize: '12px',
    paddingTop: '10px',
    paddingBottom: '10px',
    lineHeight: 'normal',
  },
  'td:first-child': {
    borderTopLeftRadius: '6px',
    borderBottomLeftRadius: '6px',
    paddingLeft: '20px',
  },
  'td:last-child': {
    borderTopRightRadius: '6px',
    borderBottomRightRadius: '6px',
  },
}));

export const TooltipedEllipsis = ({
  text,
  maxLength,
  topTooltip,
}: {
  text: string;
  maxLength: number;
  topTooltip?: boolean;
}) =>
  text.length < maxLength ? (
    <span>{text}</span>
  ) : (
    <Tooltip
      tooltipContent={text}
      bgColor="light"
      className="cde-ellipsis-tooltip"
      portal={topTooltip}
      showShadow
    >
      <span>{text.slice(0, maxLength - 3) + '...'}</span>
    </Tooltip>
  );

export const ToReviewBeforeTableHeader: React.FC<ToReviewBeforeTableHeaderProps> = ({
  reviewBefore,
}) => (
  <div className="to-review-before-header">
    <span>Review by{<br />} date</span>
    <Tooltip
      tooltipContent={`Till ${formatDateToLocalHours(reviewBefore)}`}
      bgColor="light"
      placement="top"
      className="review-tooltip"
      showShadow
    >
      <i className="icon-help-c-2" />
    </Tooltip>
  </div>
);

export const ExtractedOnBehalfTableHeader: React.FC = () => (
  <div className="extracted-on-behalf-of">
    <span>Extracted on{<br />}behalf of</span>
    <Tooltip
      tooltipContent="Extracted from user's instance leveraging Vim technology"
      bgColor="light"
      placement="top"
      className="review-tooltip"
      showShadow
    >
      <i className="icon-help-c-2" />
    </Tooltip>
  </div>
);
