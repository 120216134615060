import { injectable } from 'inversify';
import { LogMetadata } from '../types';

/**
 * Class that hold configuration for container scoped level.
 */
@injectable()
export class LoggerScopedConfig {
  public moduleName?: string;
  #metadata: Partial<LogMetadata> = {};

  get metadata() {
    return this.#metadata;
  }

  public setMetadata(metadata: Partial<LogMetadata>) {
    this.#metadata = {
      ...metadata,
      adapterName: metadata.adapterName?.toLowerCase(),
    };
  }
  public updateMetadata(paramsToUpdate: Partial<LogMetadata>) {
    this.#metadata = {
      ...this.#metadata,
      ...paramsToUpdate,
    };
    if (this.#metadata.adapterName)
      this.#metadata.adapterName = this.#metadata.adapterName.toLowerCase();
  }
}
