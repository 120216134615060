import { AppToOSMessageTypes, EHRResource } from '@getvim/vim-os-api';
import { ErrorCodes } from '@getvim-os/errors';
import { EHR, ErrorMessageResponseType } from '@getvim/vim-os-sdk-api';
import { EhrAPI, OsCommunicator } from '.';

const MESSAGE_RESPONSE_TIMEOUT = 30_000; // 30 seconds

type ResourceId = string;

type ArgumentsType<T> = T extends (...args: infer U) => any ? U : never;

export interface IEhrResourceEnhancementsExecutor
  extends EHR.AddResourceIDAndTimeoutArg<EHR.AllEnhancements> {
  getProblemList(
    resourceId: ResourceId,
    timeoutMS?: number | undefined,
  ): Promise<EHR.Diagnosis[] | undefined>;
}

export class EhrResourceEnhancementsExecutor<EHR_STATE extends EHR.EHR_STATE_TEMPLATE>
  implements IEhrResourceEnhancementsExecutor
{
  private ehrResourcesChangeTracker: Partial<Record<EHR.EHRResource, string>> = {};

  private ehrResourcesAbortControllers: Partial<Record<EHR.EHRResource, AbortController>> = {};

  constructor(private osCommunicator: OsCommunicator, ehr: EhrAPI<EHR_STATE>) {
    ehr.subscribe(EHR.EHRResource.patient, (patient) => {
      if (this.ehrResourcesChangeTracker.patient !== patient?.identifiers?.vimPatientId) {
        this.ehrResourcesAbortControllers.patient?.abort?.();
        this.ehrResourcesAbortControllers.patient = undefined;
      }
    });
    ehr.subscribe(EHR.EHRResource.referral, (referral) => {
      if (this.ehrResourcesChangeTracker.referral !== referral?.identifiers?.vimReferralId) {
        this.ehrResourcesAbortControllers.referral?.abort?.();
        this.ehrResourcesAbortControllers.referral = undefined;
      }
    });
    ehr.subscribe(EHR.EHRResource.encounter, (encounter) => {
      if (this.ehrResourcesChangeTracker.encounter !== encounter?.identifiers?.ehrEncounterId) {
        this.ehrResourcesAbortControllers.encounter?.abort?.();
        this.ehrResourcesAbortControllers.encounter = undefined;
      }
    });
    ehr.subscribe(EHR.EHRResource.orders, (orders) => {
      if (
        this.ehrResourcesChangeTracker.orders &&
        (!orders ||
          !orders
            .map((order) => order?.identifiers?.ehrOrderId)
            .includes(this.ehrResourcesChangeTracker.orders))
      ) {
        this.ehrResourcesAbortControllers.orders?.abort?.();
        delete this.ehrResourcesAbortControllers.orders;
      }
    });
  }

  protected async executeResourceEnhancement<
    T extends EHR.EHRResource,
    E extends keyof EHR.EHRResourceEnhancementsApi<T>,
  >(
    resource: T,
    _enhancement: E,
    input: ArgumentsType<EHR.EHRResourceEnhancementsApi<T>[E]>,
    resourceId: ResourceId,
    timeout = MESSAGE_RESPONSE_TIMEOUT,
  ): Promise<
    Awaited<
      Required<EHR.EHRResourceEnhancementsApi<T>>[E] extends (...args: any) => any
        ? ReturnType<Required<EHR.EHRResourceEnhancementsApi<T>>[E]>
        : EHR.EHRResourceEnhancementsApi<T>[E]
    >
  > {
    const enhancement = _enhancement as string;

    const abortController = new AbortController();
    this.ehrResourcesChangeTracker[resource] = resourceId;
    this.ehrResourcesAbortControllers[resource] = abortController;

    return await new Promise<Awaited<ReturnType<typeof this.executeResourceEnhancement>>>(
      (resolve, reject) => {
        const onAbort = () => {
          reject({
            type: ErrorMessageResponseType.preconditions_error,
            data: `EHR Resource Changed, requested enhancement is irrelevant.`,
            code: ErrorCodes.PRECONDITIONS,
          });
        };
        abortController.signal.addEventListener('abort', onAbort);
        this.osCommunicator
          .sendAwaitedMessage(
            {
              type: AppToOSMessageTypes.EXECUTE_RESOURCE_ENHANCEMENT,
              payload: {
                resource: resource as EHRResource,
                enhancement,
                input,
                resourceId,
              },
            },
            timeout,
          )
          .then((result) => {
            abortController.signal.removeEventListener('abort', onAbort);
            resolve(result);
          })
          .catch((error) => {
            reject(error);
          });
      },
    );
  }

  getProblemList = async (resourceId: ResourceId, timeoutMS?: number | undefined) => {
    return await this.executeResourceEnhancement(
      EHR.EHRResource.patient,
      EHR.PatientEnhancements.GET_PROBLEM_LIST,
      [],
      resourceId,
      timeoutMS,
    );
  };
}
