// eslint-disable-next-line check-file/filename-naming-convention
import { injectable } from 'inversify';
import { scopeColors } from './scope-colors';
import { LogLevel, LogOptions } from '../types';
import { getEnvByOrigin } from './get-url-by-env';

@injectable()
export class ConsoleTransport {
  log(
    scope: string,
    moduleName: string,
    level: LogLevel,
    message?: string,
    error?: Error,
    data?: unknown | Error,
    options?: LogOptions,
  ) {
    const scopeColor = this.selectTagColor(scope);
    const moduleColor = this.selectTagColor(moduleName);
    const { messageStyle = [] } = options || {};
    const levelStyle = this.getLogLevelStyle(level);
    if (!this.shouldLogConsole(level)) return;
    console[level](
      `%c ${moduleName} %c (${level}) %c [${scope}] %c ${message || '(no message)'}`,
      `background: ${moduleColor}; color: white;`,
      levelStyle,
      `color: ${scopeColor};`,
      '',
      ...messageStyle,
      ...(data instanceof Error ? ['\n\n', data] : [data || '']),
      ...(error ? ['\n\n', error] : []),
    );
  }

  private consoleLogLevelFromStorage = localStorage.getItem('VIM_CONSOLE_LOG_LEVEL');

  /**
   * Selecting a color for the tag using the hash of the tag in order
   * to make the tag color constant for each tag.
   * @param tag - used to determine the color for consistent tag color.
   * @param colors
   */
  private selectTagColor(tag: string, colors: string[] = scopeColors): string {
    let hash = 0;

    for (let i = 0; i < tag.length; i++) {
      // eslint-disable-next-line unicorn/prefer-code-point
      hash = (hash << 5) - hash + tag.charCodeAt(i);
      hash |= 0; // Convert to 32bit integer
    }

    return colors[Math.abs(hash) % colors.length];
  }

  private getLogLevelStyle(logLevel: LogLevel) {
    switch (logLevel) {
      case LogLevel.WARNING:
        return 'background: #EEAA79';
      case LogLevel.ERROR:
        return 'background: #BC2214';
      default:
        return '';
    }
  }

  private shouldLogConsole(level: LogLevel) {
    const consoleLogLevelString =
      (globalThis as any).$vim_environment?.VIM_CONSOLE_LOG_LEVEL ??
      this.consoleLogLevelFromStorage ??
      this.getConsoleLogLevelByEnv();
    return this.logLevelOrder[level] <= this.logLevelOrder[consoleLogLevelString];
  }

  private getConsoleLogLevelByEnv() {
    return getEnvByOrigin(origin) === 'prod' ? 'disable' : 'debug';
  }

  private logLevelOrder: { [key in LogLevel | 'warning' | 'disable']: number } = {
    ['disable']: 1,
    [LogLevel.ERROR]: 2,
    [LogLevel.WARNING]: 3,
    ['warning']: 3, // compatability with ScopedLogger
    [LogLevel.INFO]: 4,
    [LogLevel.DEBUG]: 5,
  };
}
