import { Team } from '@getvim/vim-code-ownership/team';

export type LoggerTags = {
  team?: Team;
  moduleName?: string;
  statisticWarning?: boolean;
};

export interface LogMetadata {
  adapterName?: string;
  deviceId: string;
  version: string;
  vimVersion?: string;
  windowId: number | string;
  tabId?: number;
  msiVersion?: string;
  extensionVersion?: string;
  hostname?: string;
  osUsername?: string;
  linkedEhrUser?: string;
  userSessionId?: string;
  organization_name?: string;
  organizationId?: number;
  organizationTag?: string;
  transactionId?: string;
  v2Adapter?: boolean;
  appName?: string;
  appId?: string;
  appSessionId?: string;
  appHandshakeTraceId?: string;
  appUrl?: string;
  tabVisibility?: string;
  monitorPaused?: boolean;
}

export enum LogLevel {
  DEBUG = 'debug',
  INFO = 'info',
  WARNING = 'warn',
  ERROR = 'error',
}

export interface LogOptions {
  local?: boolean;
  messageStyle?: string[];
}

export enum BackendService {
  CONSOLE = 'console',
  LOGGER = 'logger',
}
