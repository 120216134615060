import * as React from 'react';
import { StyledTableCell, StyledTableRow, TooltipedEllipsis } from './StyledComponents';
import { AllRequestsTableRowProps } from '../../types/table';
import './Tables.less';
import StatusElement from './StatusElement';
import { formatDataRequester } from '../../formatters';

export const AllRequestsTableRow: React.FC<AllRequestsTableRowProps> = ({ row, index }) => {
  const isFirstRow = index === 0;
  const formattedDataRequester = formatDataRequester(row.dataRequester, row.originalRequester);

  return (
    <StyledTableRow>
      <StyledTableCell className={`two-rows-ellipsis-cell ${isFirstRow && 'first-row'}`}>
        <TooltipedEllipsis text={row.patientName} maxLength={45} topTooltip={!isFirstRow} />
      </StyledTableCell>
      <StyledTableCell>{row.patientDateOfBirth}</StyledTableCell>
      <StyledTableCell
        className={`two-rows-ellipsis-cell no-word-break ${isFirstRow && 'first-row'}`}
      >
        <TooltipedEllipsis text={formattedDataRequester} maxLength={35} topTooltip={!isFirstRow} />
      </StyledTableCell>
      <StyledTableCell>{row.chartRequestRange}</StyledTableCell>
      <StyledTableCell
        className={`two-rows-ellipsis-cell extracted-cell ${isFirstRow && 'first-row'}`}
      >
        <TooltipedEllipsis text={row.extractedWith} maxLength={35} topTooltip={!isFirstRow} />
      </StyledTableCell>
      <StyledTableCell className="status-chip">
        <StatusElement
          status={row.status}
          details={row.details}
          sharedAt={row.sharedAt}
          expiration_date={row.expirationDate}
          isFirstRow={isFirstRow}
        />
      </StyledTableCell>
    </StyledTableRow>
  );
};
