import { getLogger } from '../logger';

const actionsLogger = getLogger({ scope: 'cde-actions' });

export const logAction = (actionName: string) => {
  actionsLogger.info(`Run Action`, {
    action: actionName,
    timestamp: Date.now(),
  });
};

export const logFinishAction = (actionName: string, startTime: number) => {
  const finishActionTime = Date.now();

  actionsLogger.info(`Finish Action`, {
    action: actionName,
    timestamp: finishActionTime,
    timeTaken: `${Math.floor(finishActionTime - startTime)} ms`,
  });
};
