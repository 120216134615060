import { ChipProps } from '@getvim/atomic-ui';

export enum STATUS_CHIP_ENUM {
  PROCESSING = 'processing',
  REJECTED = 'rejected',
  FAILED = 'failed',
  SHARED = 'shared',
  NO_DATA = 'no_data',
  PATIENT_DOESNT_EXIST = 'patient_doesnt_exist',
  DATA_RESTRICTED = 'data_restricted',
  EXPIRED = 'expired',
  ENCOUNTER_NOT_LOCKED_EXPIRED = 'encounter_not_locked_expired',
  ENCOUNTER_NOT_LOCKED_FAILED = 'encounter_not_locked_failed',
}

export interface ChipPropsWrapper extends ChipProps {
  info: string;
}
