import { StandardError } from '@getvim-os/errors';
import { Messages, formatString } from './templates';
import { AppToOSMessageTypes } from '@getvim/vim-os-api';

const UserFriendlyMethods: Partial<
  Record<AppToOSMessageTypes, string>
> = {
  [AppToOSMessageTypes.DISPLAY_PUSH_NOTIFICATION]: 'push notifications',
  [AppToOSMessageTypes.TRACK_ANALYTICS]: 'analytics',
  [AppToOSMessageTypes.ENABLE_LAUNCH_BUTTONS]: 'launch button',
  [AppToOSMessageTypes.POP_UP]: 'popup',
  [AppToOSMessageTypes.SET_NOTIFICATIONS_INDICATOR]: 'badge notification',
  [AppToOSMessageTypes.CLEAR_NOTIFICATIONS_INDICATOR]: 'badge notification',
};
export default class NotAllowedError extends StandardError {
  constructor(method: AppToOSMessageTypes) {
    super(formatString(Messages.NOT_ALLOWED, UserFriendlyMethods[method]));
  }
}
