export enum FileExtension {
  PDF = 'pdf',
  JSON = 'json',
  HTML = 'html',
}

export interface FileMetadata {
  name: string;
  data: Blob;
  extension: FileExtension;
}

export interface FileResult {
  path: string;
}
