import { useApi, useVimAuth } from '@getvim/internal-vim-os-sdk/react';
import { useEffect, useMemo, useState } from 'react';
import { appLogger } from './App';
import { AnalyticsManager } from '../analytics/analyticsManager';
import { AdapterActionApi, useSdkEhrAction } from '../api/adapter';
import { setAccessToken } from '../logic/access-token';

export const useSdkAnalytics = () => {
  const { trackAnalytics } = useApi();
  const [analyticsReady, setAnalyticsReady] = useState<boolean>(false);

  const analyticsClient = useMemo(
    () => ({
      trackAnalytics,
    }),
    [trackAnalytics],
  );

  useEffect(() => {
    if (!analyticsClient?.trackAnalytics) {
      appLogger.warning('analyticsClient not initialized yet');
      return;
    }

    AnalyticsManager.setAnalyticsClient(analyticsClient);
    setAnalyticsReady(true);
    appLogger.info('analyticsClient initialized successfully');
  }, [analyticsClient]);

  return analyticsReady;
};

export const useSdkAccessToken = () => {
  const { accessToken } = useVimAuth();

  useEffect(() => {
    if (!accessToken) {
      return;
    }

    setAccessToken(accessToken);
  }, [accessToken]);

  return accessToken;
};

export const useSdkActions = () => {
  const runAction = useSdkEhrAction();
  const [runActionReady, setRunActionReady] = useState<boolean>(false);

  useEffect(() => {
    AdapterActionApi.setRunActionCallback(runAction);
    setRunActionReady(true);
  }, [runAction]);

  return runActionReady;
};
