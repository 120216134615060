import * as React from 'react';
import TableBody from '@mui/material/TableBody';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { ExtractedOnBehalfTableHeader, StyledTable, StyledTableCell } from './StyledComponents';
import { PatientRequestsTableProps } from '../../types/table';
import './Tables.less';
import { AllRequestsTableRow } from './AllRequestsTableRow';

const AllRequestsTable: React.FC<Omit<PatientRequestsTableProps, 'handleActionClick'>> = ({
  rows,
}) => {
  return (
    <StyledTable className="cde-table" stickyHeader>
      <TableHead className="cde-table-thead all-requests">
        <TableRow>
          <StyledTableCell width="160px">Patient name</StyledTableCell>
          <StyledTableCell width="120px">Patient DOB</StyledTableCell>
          <StyledTableCell width="105px">Data requester</StyledTableCell>
          <StyledTableCell width="100px">Chart request{<br />}range</StyledTableCell>
          <StyledTableCell>
            <ExtractedOnBehalfTableHeader />
          </StyledTableCell>
          <StyledTableCell width="127px">Status</StyledTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {rows.map((row, index) => {
          return <AllRequestsTableRow row={row} index={index} key={row.patientRequestId} />;
        })}
      </TableBody>
    </StyledTable>
  );
};

export default AllRequestsTable;
