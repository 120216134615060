import React, { useCallback } from 'react';
import { formatPatientRequestsTable } from '../../formatters';
import OpenRequestsTable from '../table/OpenRequestsTable';
import TabContentBanner from './tab-content-banner/TabContentBanner';
import TabContentEmpty from './tab-content-empty/TabContentEmpty';
import TabContentError from './tab-content-error/TabContentError';
import TabContentLoader from './tab-content-loader/TabContentLoader';
import { UseFindExtendedPatientRequestsResponse } from '../../hooks/api/patient-request/useGetPatientRequestsData';
import TableActionsBar from './TableActionsBar';
import { appLogger } from '../App';
import { trackActionClicked } from '../../analytics/trackActionClicked';
import { trackActionSubmitted } from '../../analytics/trackActionSubmitted';
import { trackActionSubmissionStatus } from '../../analytics/trackActionSubmissionStatus';
import { ActionAnalyticsType } from '../../types/table';

const OpenRequestsTab: React.FC<UseFindExtendedPatientRequestsResponse> = ({
  data,
  setCurrentPage,
  currentPage,
  isFetching,
  dataUpdatedAt,
  refetch,
}: UseFindExtendedPatientRequestsResponse) => {
  const handleTrackAction = useCallback(
    (recordId: string, actionType: ActionAnalyticsType, responseStatus?: boolean) => {
      const record = data?.extendedPatientRequestsDetails.find((item) => item?.id === recordId);

      const commonParams = {
        data_requester: record?.dataRequester ? [record?.dataRequester] : [],
        action_type: 'reject',
        vim_patient_id: record?.vimPatientId,
        pseudonymized_patient_id: record?.pseudonymizedId,
        extraction_status: record?.status,
        extraction_from_date: record?.extractionDateRange?.fromDate,
        extraction_to_date: record?.extractionDateRange?.untilDate,
        to_review_before_date: record?.toReviewBefore,
        approval_status: record?.approvalStatus?.status,
        extracted_with_first_name: record?.extractedWith?.firstName,
        extracted_with_last_name: record?.extractedWith?.lastName,
      };

      switch (actionType) {
        case ActionAnalyticsType.ACTION_CLICKED:
          return trackActionClicked(commonParams);
        case ActionAnalyticsType.ACTION_SUBMITTED:
          return trackActionSubmitted(commonParams);
        case ActionAnalyticsType.ACTION_SUBMISSION_STATUS:
          return trackActionSubmissionStatus({
            ...commonParams,
            status: responseStatus ? 'success' : 'failed',
          });
      }
    },
    [data?.extendedPatientRequestsDetails],
  );

  if (isFetching) return <TabContentLoader />;

  if (!data) {
    appLogger.info('data is not defined on OpenRequestsTab component, showing error tab', {
      data,
      isFetching,
      currentPage,
      dataUpdatedAt,
    });
    return <TabContentError />;
  }

  if (data.extendedPatientRequestsDetails.length === 0) return <TabContentEmpty />;

  return (
    <>
      <div className="table-top-header">
        <TabContentBanner
          reviewBefore={data.extendedPatientRequestsDetails[0]?.toReviewBefore}
        ></TabContentBanner>

        <TableActionsBar
          paging={{
            currentPage,
            setCurrentPage,
            isFetching,
            totalResults: data?.paginationResult.totalResults,
          }}
          dataUpdatedAt={dataUpdatedAt}
          refetch={refetch}
        />
      </div>

      {isFetching ? (
        <TabContentLoader />
      ) : (
        <div className="patient-requests-table-container">
          <OpenRequestsTable
            rows={formatPatientRequestsTable(data.extendedPatientRequestsDetails)}
            handleActionClick={handleTrackAction}
          />
        </div>
      )}
    </>
  );
};

export default OpenRequestsTab;
