import { z } from 'zod';
import { buttonStyleSchema } from '.';

export const actionButtonSchema = z.object({
  id: z.string(),
  text: z.string(),
  buttonStyle: buttonStyleSchema,
  openAppButton: z.boolean().optional(),
});

export const pushNotificationPayloadSchema = z.object({
  text: z.string(),
  notificationId: z.string(),
  timeoutInSec: z.number().optional(),
  actionButtons: z
    .object({
      leftButton: actionButtonSchema.optional(),
      rightButton: actionButtonSchema.optional(),
    })
    .optional(),
});

export const setNotificationIndicatorPayloadSchema = z.number();
export const clearNotificationIndicatorPayloadSchema = z.undefined();
export const hideNotificationPayloadSchema = z.undefined();

export type SdkPushNotificationPayload = z.infer<typeof pushNotificationPayloadSchema>;

export type ActionButton = z.infer<typeof actionButtonSchema>;
