import { useApi } from '@getvim/internal-vim-os-sdk/react';
import { ActionNames } from '@getvim/internal-vim-os-sdk/types';
import { camelCase } from 'lodash-es';
import { useCallback } from 'react';

export type RunEhrActionCallback = (
  action: ActionNames,
  input: unknown,
  timeoutMS?: number,
) => Promise<any>;

export const useSdkEhrAction = (): RunEhrActionCallback => {
  const { ehrActions } = useApi();

  return useCallback(
    async (action: ActionNames, input: unknown, timeoutMS?: number) => {
      return await ehrActions[camelCase(action)](input, timeoutMS);
    },
    [ehrActions],
  );
};
