import hash from 'object-hash';

interface SessionPayload {
  userExternalId?: string;
  deviceId?: string;
  date: string;
}

class SessionIdManager {
  private sessionId: string | undefined;

  public getId(): string | undefined {
    return this.sessionId;
  }

  private handleUpdateId(payload: SessionPayload): void {
    this.sessionId = hash.MD5(payload);
  }

  public handleShouldUpdate(payload: Omit<SessionPayload, 'date'>): string | undefined {
    this.handleUpdateId({
      ...payload,
      date: new Date().toLocaleDateString(),
    });

    return this.sessionId;
  }
}

export const sessionIdManager = new SessionIdManager();
