import type { EHR_EVENT, Notification } from '@getvim-os/types';

import type {
  EHRResource,
  EnrichedUserPayload,
  PushNotificationResponse,
  ServiceCustomPayload,
  ServiceEnrichedEhrPayload,
  ServiceEnrichedUserPayload,
  VimAuth,
} from '@getvim/vim-os-api';

import { Hub, EHR } from '@getvim/vim-os-sdk-api';
import { IInternalResourceUpdater as IInternalResourceUpdaterDynamic } from '@getvim/dynamic-vim-os-sdk/types';

import { InternalSDKToAppMessageTypes } from './outgoingMessagesToApp';

export type { EHR_EVENT } from '@getvim-os/types';
export { PatientEnhancements } from '@getvim-os/types';

export type {
  AppLaunchButtonClickPayload,
  OSToAppMessageEvent,
  VimAuth,
  UpdatableEhrFields,
  EnableLaunchButtonPayload,
} from '@getvim/vim-os-api';
export { EHRResource, OrdersRealEstate, ReferralRealEstate } from '@getvim/vim-os-api';

export * from './ehrActions';
export type * from './ehrState';
export type * from './analytics';
export * from './outgoingMessagesToApp';

export type AppStateSubscription = Hub.AppStateSubscription;
export const ActivationStatus = Hub.ActivationStatus;
export const ApplicationSize = Hub.ApplicationSize;
export const ButtonStyle = Hub.ButtonStyle;

export type ActivationStatus = Hub.ActivationStatus;
export type ApplicationSize = Hub.ApplicationSize;
export type ButtonStyle = Hub.ButtonStyle;

export type PushNotificationPayload = Hub.PushNotificationPayload;

export type UpdatableReferral = EHR.UpdateReferralParams;
export type UpdatableReferralField = EHR.UpdateReferralField;
export type UpdatableEncounter = EHR.UpdateEncounterParams;
export type IInternalResourceUpdater = IInternalResourceUpdaterDynamic;

export type SHARED_SERVICE_DATA = {
  enrichedEHR?: {
    [Resource in EHRResource]?: any;
  };
  enrichedUser?: EnrichedUserPayload;
  customMessage?: any;
};

export enum SDKToAppMessageServiceTypes {
  SERVICE_ENRICHED_EHR = 'SERVICE_ENRICHED_EHR',
  SERVICE_ENRICHED_USER = 'SERVICE_ENRICHED_USER',
  SERVICE_CUSTOM_MESSAGE = 'SERVICE_CUSTOM_MESSAGE',
}

export type SDKToAppMessageTypes = InternalSDKToAppMessageTypes | SDKToAppMessageServiceTypes;

export interface SDKToAppEventListener<T extends SDKToAppMessageTypes> {
  (evt: SDKToAppMessageEvent<T>): void;
}

export type SDKToAppMessageEvent<T extends SDKToAppMessageTypes> = Event &
  T extends InternalSDKToAppMessageTypes.ON_EHR_EVENT
  ? // ON_EHR_EVENT is not yet supported in the public sdk
    { detail: EHR_EVENT }
  : // Services are not yet supported in the public sdl
  T extends SDKToAppMessageServiceTypes.SERVICE_ENRICHED_EHR
  ? { detail: ServiceEnrichedEhrPayload }
  : T extends SDKToAppMessageServiceTypes.SERVICE_ENRICHED_USER
  ? { detail: ServiceEnrichedUserPayload }
  : T extends SDKToAppMessageServiceTypes.SERVICE_CUSTOM_MESSAGE
  ? { detail: ServiceCustomPayload }
  : T extends InternalSDKToAppMessageTypes.ON_NOTIFICATION_ACTION
  ? PushNotificationResponse
  : T extends InternalSDKToAppMessageTypes.ON_READY
  ? undefined
  : T extends InternalSDKToAppMessageTypes.APP_STATE
  ? Hub.AppStateData
  : T extends InternalSDKToAppMessageTypes.AUTH_TOKEN_SET
  ? { detail: VimAuth }
  : never;

export type ActionButton = Notification.ActionButton & {
  callback: () => void;
};
