import { analyticsManager } from './analyticsManager';
import { parseDataRequester } from './patientRequests';
import { ExtendedPatientRequestsDetailsResponse } from '../api/bff';

export const trackAppEnabled = ({
  requests,
  userRoles,
  applicationVersion,
}: {
  requests?: {
    openRequests: ExtendedPatientRequestsDetailsResponse;
    allRequests: ExtendedPatientRequestsDetailsResponse;
  };
  userRoles: string[];
  applicationVersion: number;
}): void => {
  analyticsManager.track('cde_app_enabled', {
    app_source_displayed: analyticsManager.getDatasource(),
    data_requester: parseDataRequester([
      ...(requests?.openRequests?.extendedPatientRequestsDetails ?? []),
      ...(requests?.allRequests?.extendedPatientRequestsDetails ?? []),
    ]),
    app_notifications_count: requests?.openRequests?.paginationResult.totalResults ? 1 : 0,
    items_received_count:
      (requests?.openRequests?.paginationResult.totalResults ?? 0) +
      (requests?.allRequests?.paginationResult.totalResults ?? 0),
    open_items_count: requests?.openRequests?.paginationResult.totalResults ?? 0,
    other_items_count: requests?.allRequests?.paginationResult.totalResults ?? 0,
    user_roles: userRoles,
    application_version: applicationVersion,
  });
};
