import { ErrorCodes } from './error-codes';

export class StandardError extends Error {
  constructor(message: string, public code = ErrorCodes.INTERNAL_ERROR, properties: object = {}) {
    super(message);
    for (const key of Object.keys(properties)) {
      this[key] = properties[key];
    }
  }

  public toJSON() {
    return Object.fromEntries(Object.getOwnPropertyNames(this).map((key) => [key, this[key]]));
  }
}
