import React from 'react';
import { startCase, lowerCase } from 'lodash-es';
import { ExtendedPatientRequestsDetails } from '../api/bff';
import { PatientRequestsTableRow } from '../types/table';
import { APPROVAL_STATUS, PATIENT_REQUEST_STATUS } from '../types';
import { appLogger } from '../components/App';
import { formatDate, formatToLocalDate } from '.';

const formatPersonFullName = (lastName?: string, firstName?: string) => {
  if (!lastName && !firstName) return '-';
  if (!lastName) return `${startCase(lowerCase(firstName))}`;
  if (!firstName) return `${startCase(lowerCase(lastName))}`;
  return `${startCase(lowerCase(lastName))}, ${startCase(lowerCase(firstName))}`;
};

export const formatDataRequester = (
  dataRequester: string,
  originalRequester: string | undefined,
): string => {
  if (!originalRequester || dataRequester.toLowerCase() === originalRequester?.toLowerCase()) {
    return dataRequester;
  }
  return `${originalRequester} via ${dataRequester}`;
};

export const formatPatientRequestsTable = (
  data: ExtendedPatientRequestsDetails[],
): PatientRequestsTableRow[] =>
  data.map((record) => {
    if (
      record.approvalStatus.status === APPROVAL_STATUS.REJECTED &&
      record.status === PATIENT_REQUEST_STATUS.SHARED
    ) {
      appLogger.error('approval status is Rejected and patient request status is Shared');
    }
    return {
      patientRequestId: record.id,
      recordId: record.dataRequestId,
      patientName: formatPersonFullName(record.patientName.lastName, record.patientName.firstName),
      patientDateOfBirth: formatDate(record.dateOfBirth, 'MM/DD/YYYY') || '-',
      dataRequester: startCase(record.dataRequester) || '-',
      originalRequester: startCase(record.originalRequester),
      chartRequestRange: (
        <span>
          {record.extractionDateRange.fromDate} to
          <br />
          {record.extractionDateRange.untilDate}
        </span>
      ),
      reviewBefore: record.toReviewBefore || '-',
      extractedWith: record.extractedWith
        ? formatPersonFullName(record.extractedWith.lastName, record.extractedWith.firstName)
        : '-',
      status:
        record.approvalStatus.status === APPROVAL_STATUS.REJECTED
          ? PATIENT_REQUEST_STATUS.REJECTED
          : record.status || '-',
      details: record.details,
      sharedAt: record.sharedAt
        ? formatToLocalDate(record.sharedAt?.toString(), 'MM/DD/YYYY')
        : undefined,
      expirationDate: record.expirationDate,
    };
  });
