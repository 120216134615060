import React from 'react';
import { ColorNameEnum, WeightEnum, SizeEnum, Text } from '@getvim/atomic-ui';

import './TabContentError.less';

const TabContentError: React.FC<object> = () => {
  return (
    <div className="patient-request-error-container">
      <div>
        <Text
          colorName={ColorNameEnum.theme}
          text="Oops."
          weight={WeightEnum.bold}
          size={SizeEnum['18px']}
        />
        <Text
          colorName={ColorNameEnum.theme}
          text="Something went wrong."
          size={SizeEnum['14px']}
          className="error-state-title"
        />
      </div>
      <img className="ghostImage" src="https://static.getvim.com/img/ghost.svg" alt="title" />
    </div>
  );
};

export default TabContentError;
