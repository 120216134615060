import { inject, injectable } from 'inversify';
import { RateLimiter } from 'limiter';
import { LoggerScopedConfig, LoggerGlobalConfig } from './configs';
import { rateLimiterId } from './rate-limiter';
import { ConsoleTransport, ServerTransport } from './transports';
import { LogLevel, LogMetadata, LogOptions, LoggerTags } from './types';

@injectable()
export abstract class Logger {
  public info(
    message: string,
    data?: unknown | Error,
    error?: Error,
    options?: LogOptions,
    tags?: LoggerTags,
  ): void {
    this.log(LogLevel.INFO, message, data, options, tags, error);
  }

  public error(
    message: string,
    data?: unknown | Error,
    error?: Error,
    options?: LogOptions,
    tags?: LoggerTags,
  ): void {
    this.log(
      LogLevel.ERROR,
      message,
      data,
      options,
      tags,
      error ?? (data instanceof Error ? data : error),
    );
  }

  public errorIfThrow<T>(
    promiseCB: () => Promise<T>,
    message: string,
    data?: unknown,
    options?: LogOptions,
    tags?: LoggerTags,
  ): Promise<T> {
    return promiseCB().catch((error) => {
      this.error(message, data, error, options, tags);
      throw error;
    });
  }

  public debug(
    message: string,
    data?: unknown | Error,
    error?: Error,
    options?: LogOptions,
    tags?: LoggerTags,
  ): void {
    this.log(LogLevel.DEBUG, message, data, options, tags, error);
  }

  public warning(
    message: string,
    data?: unknown | Error,
    error?: Error,
    options?: LogOptions,
    tags?: LoggerTags,
  ): void {
    this.log(LogLevel.WARNING, message, data, options, tags, error);
  }

  public abstract log(
    level: LogLevel,
    message: string,
    data?: unknown,
    options?: LogOptions,
    tags?: LoggerTags,
    error?: Error,
  ): void;
}

@injectable()
export class VimOsLogger extends Logger {
  public scope = '';
  public moduleName?: string = undefined;
  public tags: LoggerTags = {};
  private isBlocked = false;

  constructor(
    @inject(LoggerGlobalConfig) private loggerGlobalConfig: LoggerGlobalConfig,
    @inject(LoggerScopedConfig) private loggerScopedConfig: LoggerScopedConfig,
    @inject(ConsoleTransport) private consoleTransport: ConsoleTransport,
    @inject(ServerTransport) private serverTransport: ServerTransport,
    @inject(rateLimiterId) private rateLimiter: RateLimiter,
  ) {
    super();
  }

  private shouldLog(remainingRequests: number) {
    return !this.loggerGlobalConfig.limiterSupported || remainingRequests >= 0;
  }

  public updateMetadata(paramsToUpdate: Partial<LogMetadata>) {
    this.loggerGlobalConfig.updateMetadata(paramsToUpdate);
  }

  public async log(
    level: LogLevel,
    message: string,
    data?: unknown,
    options?: LogOptions,
    tags?: LoggerTags,
    error?: Error,
  ): Promise<unknown> {
    try {
      this.consoleTransport.log(
        this.scope,
        this.moduleName ??
          this.loggerScopedConfig.moduleName ??
          this.loggerGlobalConfig.moduleName ??
          '',
        level,
        message,
        error,
        data,
        options,
      );

      const shouldSendToBackendByLevel = level !== LogLevel.DEBUG;

      const time = Date.now();
      const remainingRequests = shouldSendToBackendByLevel
        ? await this.rateLimiter.removeTokens(1)
        : this.rateLimiter.getTokensRemaining();

      if (this.shouldLog(remainingRequests)) {
        this.isBlocked = false;

        if (!options?.local && this.loggerGlobalConfig.remoteLogSupported) {
          return shouldSendToBackendByLevel
            ? await this.serverTransport.log(
                this.scope,
                level,
                message,
                time,
                error,
                data,
                {
                  ...this.loggerGlobalConfig.staticTags,
                  ...this.tags,
                  ...tags,
                },
                {
                  tabVisibility: document.visibilityState,
                  ...this.loggerGlobalConfig.metadata,
                  ...this.loggerScopedConfig.metadata,
                },
              )
            : undefined;
        }
      } else {
        const message = `Scopped logger get blocked due reaching rate limit`;
        !this.isBlocked &&
          shouldSendToBackendByLevel &&
          (await this.serverTransport.log(this.scope, level, message, time, error, data, {
            ...this.loggerGlobalConfig.staticTags,
            ...this.tags,
            ...tags,
          }));

        this.isBlocked = true;
      }
    } catch (error) {
      console.warn('error while writing to log.', error);
    }
  }
}
