import { OsCommunicator } from '@getvim/dynamic-vim-os-sdk/sdk';
import { AppToOSMessageTypes, HandshakePayload } from '@getvim/vim-os-api';

export class InternalOsCommunicator extends OsCommunicator {
  constructor(handshakePayload: HandshakePayload, osMessageChannel: MessageChannel) {
    super(handshakePayload, osMessageChannel);

    this.permissionMap = {
      ...this.permissionMap,
      [AppToOSMessageTypes.TRACK_ANALYTICS]: 'vimCapabilities.trackAnalytics',
      [AppToOSMessageTypes.ENABLE_LAUNCH_BUTTONS]: 'EHR.launchButtonRealEstate',
    };
  }
}
