import { Subscription as ISubscription } from '@getvim/vim-os-sdk-api';

export abstract class Subscription<DATA_TYPE> implements ISubscription.Subscription<DATA_TYPE> {
  constructor(initialValue: DATA_TYPE) {
    this.data = initialValue;
  }
  public subscribe<F extends keyof DATA_TYPE>(
    field: F,
    callback: ISubscription.Callback<DATA_TYPE[F]>,
  ): DATA_TYPE[F] {
    let subscribers = this.subscribers.get(field);
    if (!subscribers) {
      subscribers = new Set<ISubscription.Callback<DATA_TYPE[F]>>();
      this.subscribers.set(field, subscribers);
    }
    subscribers.add(callback);
    callback(this.data[field]);
    return this.data[field];
  }
  public unsubscribe<F extends keyof DATA_TYPE>(
    field: F,
    callback: ISubscription.Callback<DATA_TYPE[F]>,
  ): void {
    this.subscribers.get(field)?.delete(callback);
  }
  protected dispatch<F extends keyof DATA_TYPE>(field: F, data): void {
    this.data[field] = data;
    const subscribers = this.subscribers.get(field) as Set<ISubscription.Callback<DATA_TYPE[F]>>;
    if (subscribers) {
      for (const subscriber of subscribers) {
        subscriber(data);
      }
    }
  }

  private data: DATA_TYPE;
  private subscribers = new Map();
}
