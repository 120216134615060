import { getLogger } from '../../components/logger';
import { processJob } from '../../logic';
import { encryption } from '../../components/e2e-encryption';
import { setAccessToken } from '../access-token';
import { MS_SLEEP_BETWEEN_TAKE_JOB } from '../../consts';
import { jobManager } from '../../state';
import { featureFlagsClient } from '../../services';

const mainLogger = getLogger({ scope: 'main' });
const sleep = (ms: number) => new Promise<void>((res) => setTimeout(res, ms));

const processJobsRecursively = async () => {
  try {
    // eslint-disable-next-line no-constant-condition
    while (true) {
      const didTakeJob = await processJob();
      const takeJobSleepMS = await featureFlagsClient.getFlag({
        flagName: 'takeJobSleepMS',
        defaultValue: MS_SLEEP_BETWEEN_TAKE_JOB,
      });

      if (!didTakeJob) await sleep(takeJobSleepMS);
    }
  } catch {
    jobManager.clearAndNotify();
  }
};

export const initWorker = async () => {
  try {
    mainLogger.info('Starting CDE app');

    const initialAccessToken = new URLSearchParams(window.location.search).get(
      'initialAccessToken',
    );
    if (initialAccessToken) setAccessToken(initialAccessToken);
    await encryption.init();

    mainLogger.info('CDE app started');

    processJobsRecursively();
  } catch (error) {
    mainLogger.error('CDE app crashed', { error });
  }
};
