import { Notification } from '@getvim-os/types';
import {
  AppToOSMessageTypes,
  SdkPushNotificationPayload,
  OSToAppMessageEvent,
  OSToAppMessageTypes,
} from '@getvim/vim-os-api';
import { Hub } from '@getvim/vim-os-sdk-api';
import { OsCommunicator } from '..';

export class DisplayPushNotification implements Hub.IPushNotifications {
  private notificationActions: Record<string, (Hub.ActionButton & { id: string })[]> = {};
  constructor(incomingOsMessagePort: MessagePort, private osCommunicator: OsCommunicator) {
    incomingOsMessagePort.addEventListener('message', (message: OSToAppMessageEvent) => {
      if (message?.data?.type === OSToAppMessageTypes.ON_NOTIFICATION_ACTION) {
        this.pushNotificationListener(message.data.payload);
      }
    });
  }

  private pushNotificationListener = (message: Hub.PushNotificationResponse) => {
    const { event, payload } = message;
    if (event === 'action') {
      const { notificationId, actionId } = payload;
      if (notificationId && this.notificationActions[notificationId]) {
        const calledAction = this.notificationActions[notificationId].find(
          (savedAction) => savedAction.id === actionId,
        );
        if (calledAction) {
          calledAction.callback();
        }
      }
    }
  };

  public show(payload: Parameters<Hub.IPushNotifications['show']>[0]) {
    const { actionButtons, ...payloadWithNoButtons } = payload;
    const transferablePayload: SdkPushNotificationPayload = payloadWithNoButtons || {};
    if (payload.actionButtons) {
      if (!this.notificationActions[payload.notificationId]) {
        this.notificationActions[payload.notificationId] = [];
      }
      if (payload.actionButtons?.leftButton) {
        const id = 'leftButton';
        this.notificationActions[payload.notificationId].push({
          ...payload.actionButtons.leftButton,
          id,
        });
        if (payload.actionButtons?.leftButton) {
          transferablePayload.actionButtons = {
            ...transferablePayload.actionButtons,
            leftButton: { ...this.removeCb(payload.actionButtons.leftButton), id },
          };
        }
      }
      if (payload.actionButtons?.rightButton) {
        const id = 'rightButton';
        this.notificationActions[payload.notificationId].push({
          ...payload.actionButtons.rightButton,
          id,
        });
        if (payload.actionButtons?.rightButton) {
          transferablePayload.actionButtons = {
            ...transferablePayload.actionButtons,
            rightButton: { ...this.removeCb(payload.actionButtons.rightButton), id },
          };
        }
      }
    }
    this.osCommunicator.sendMessageToOS({
      type: AppToOSMessageTypes.DISPLAY_PUSH_NOTIFICATION,
      payload: transferablePayload,
    });
  }

  public hide() {
    this.osCommunicator.sendMessageToOS({
      type: AppToOSMessageTypes.HIDE_NOTIFICATION,
      payload: undefined,
    });
  }
  private removeCb = ({
    callback,
    ...actionButton
  }: Hub.ActionButton): Notification.ActionButtonNew =>
    actionButton as Notification.ActionButtonNew;
}
