import {
  OSToAppMessageEvent,
  OSToAppMessageTypes,
  ServiceCustomPayload,
  ServiceEnrichedEhrPayload,
  ServiceEnrichedUserPayload,
} from '@getvim/vim-os-api';
import { SDKToAppMessageServiceTypes } from '../types';
import { VimEventTarget } from './vimEventTarget';
export class SharedServiceApi {
  constructor(incomingOsMessagePort: MessagePort, eventTarget: VimEventTarget) {
    incomingOsMessagePort.addEventListener(
      'message',
      (message: OSToAppMessageEvent<{ withPII: true }>) => {
        switch (message?.data?.type) {
          case OSToAppMessageTypes.SERVICE_ENRICHED_EHR: {
            const event = new CustomEvent<ServiceEnrichedEhrPayload>(
              SDKToAppMessageServiceTypes.SERVICE_ENRICHED_EHR,
              {
                detail: message.data.payload,
              },
            );
            eventTarget.dispatchEvent(event);
            break;
          }
          case OSToAppMessageTypes.SERVICE_ENRICHED_USER: {
            const event = new CustomEvent<ServiceEnrichedUserPayload>(
              SDKToAppMessageServiceTypes.SERVICE_ENRICHED_USER,
              {
                detail: message.data.payload,
              },
            );
            eventTarget.dispatchEvent(event);
            break;
          }
          case OSToAppMessageTypes.SERVICE_CUSTOM_MESSAGE: {
            const event = new CustomEvent<ServiceCustomPayload>(
              SDKToAppMessageServiceTypes.SERVICE_CUSTOM_MESSAGE,
              {
                detail: message.data.payload,
              },
            );
            eventTarget.dispatchEvent(event);
            break;
          }
        }
      },
    );
  }
}
