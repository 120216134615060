import React, { useState, useCallback } from 'react';
import { Button, Loader, Popover, ProgressBar, usePopoverState } from '@getvim/atomic-ui';
import './RejectModal.less';
import { BffApi } from '../../api';
import { ActionAnalyticsType } from '../../types/table';

export type RejectModalProps = {
  requesterName: string;
  requestId: string;
  disclosure: object;
  rejectCallback: (requestId: string) => void;
  handleActionClick: (
    requestId: string,
    actionStatus: ActionAnalyticsType,
    responseStatus?: boolean,
  ) => void;
};

const ANIMATION_DELAY = 3000;

export const RejectModal: React.FC<RejectModalProps> = ({
  requesterName,
  requestId,
  disclosure,
  handleActionClick,
  rejectCallback,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const [responseStatus, setResponseStatus] = useState<boolean | undefined>(undefined);

  const rejectionPopover = usePopoverState({
    placement: 'bottom-start',
  });

  const onHide = useCallback(async () => {
    handleActionClick(requestId, ActionAnalyticsType.ACTION_SUBMITTED);
    rejectionPopover.hide();
  }, [handleActionClick, rejectionPopover, requestId]);

  const onSubmit = useCallback(async () => {
    setLoading(true);
    const responseStatus = await BffApi.rejectPatientRequests({
      patientRequestIds: [Number.parseInt(requestId)],
    });
    handleActionClick(requestId, ActionAnalyticsType.ACTION_SUBMITTED);
    setResponseStatus(responseStatus);
    if (responseStatus) {
      handleActionClick(requestId, ActionAnalyticsType.ACTION_SUBMISSION_STATUS, responseStatus);
      rejectCallback(requestId);
    }
    setLoading(false);
    setTimeout(onHide, ANIMATION_DELAY);
  }, [handleActionClick, onHide, rejectCallback, requestId]);

  return (
    <Popover
      popover={rejectionPopover}
      className="reject-modal-dialog"
      disclosure={disclosure}
      withArrow={true}
      hide={onHide}
    >
      {loading ? (
        <div className="popover-wrapper">
          <Loader type="dots" className="" label="Please wait" />
        </div>
      ) : responseStatus === undefined ? (
        <div className="popover-wrapper">
          <h4>Are you sure?</h4>
          <div className="popover-text-wrapper margin-top-10 text-12">
            You have elected to reject sharing this chart with {requesterName} as part of a clinical
            data exchange request.
            <br />
            This action cannot be reverted. Click "Yes" to proceed.
          </div>
          <div className="reject-modal-footer">
            <Button buttonType="small" bgColor="themedOutline" onClick={onHide}>
              Cancel
            </Button>
            <Button buttonType="small" bgColor="default" onClick={onSubmit}>
              Yes
            </Button>
          </div>
        </div>
      ) : responseStatus ? (
        <div className="popover-wrapper">
          <h4>Success!</h4>
          <div className="popover-text-wrapper margin-top-10 text-12">
            Patient chart requests have been rejected per your selection
          </div>
          <img className="successImage" src="https://static.getvim.com/img/success.svg" alt="" />
          <ProgressBar
            progressBarStyle="tiny"
            className="cde-popover-progressbar"
            value={100}
            animate
            duration={`${ANIMATION_DELAY}ms`}
          />
        </div>
      ) : (
        <div className="popover-wrapper">
          <h4>Something went wrong.</h4>
          <div className="popover-text-wrapper margin-top-10 text-12">
            Please try again or contact
            <br />
            <a href="mailto:support@getvim.com">
              <u>support@getvim.com</u>
            </a>
          </div>
          <div className="reject-modal-footer">
            <Button buttonType="small" bgColor="themedOutline" onClick={rejectionPopover.hide}>
              Close
            </Button>
          </div>
        </div>
      )}
    </Popover>
  );
};
