import { ContextedLogger } from './contextedLogger';

interface GetLoggerProps {
  scope: string;
}

const defaultConsoleDisplayOptions = { moduleName: 'CDE Client', moduleAndScopeSameColor: true };

export const getLogger = ({ scope }: GetLoggerProps) =>
  new ContextedLogger({ scope, options: defaultConsoleDisplayOptions });
