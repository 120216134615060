export enum Messages {
  NOT_READY = 'The VimOS SDK is not initialized yet',
  NOT_ALLOWED = '{0} is restricted in the application, permission must be requested via the application manifest.',
}

export const formatString = (message: Messages, ...args) => {
  return message.replace(/{([\d+]+)}/g, function (match, index) {
    return typeof args[index] === 'undefined' ? match : args[index];
  });
};
