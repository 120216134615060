import { ServerError } from '@apollo/client';
import { getLogger } from '../../components/logger';
import { sessionManager } from '../../state';
import { JobDetails } from '../../types';

const logger = getLogger({ scope: 'job-processing-error' });

enum ERROR_MESSAGE {
  AUTH0_VERIFY_TOKEN_FAILED = 'Context creation failed: Auth0 verify token failed',
}

const handleNetworkError = (networkError: ServerError) => {
  const { result } = networkError || {};

  if (result.errors?.some(({ message }) => message === ERROR_MESSAGE.AUTH0_VERIFY_TOKEN_FAILED)) {
    sessionManager.set({ invalidAccessToken: true });
  }
};

export const handleProcessJobError = ({ error, job }: { error: any; job?: JobDetails }): void => {
  const modifiedJob = { ...job, patient: { pseudonymizedID: job?.patient?.pseudonymizedId } };
  logger.error('Failed to process job', { modifiedJob, error });

  const { networkError } = error;

  if (networkError) handleNetworkError(networkError);
};
