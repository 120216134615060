import { gql } from '@apollo/client';
import { getLogger } from '../../components/logger';
import { CdeGqlClientManager } from './gqlClient';
import { FileResult } from '../../types';

export enum JobFinishStatus {
  SUCCESS = 'success',
  FAIL = 'fail',
}

export enum JobFinishDetails {
  PATIENT_DOESNT_EXIST = 'patient_doesnt_exist',
  DATA_RESTRICTED = 'data_restricted',
  UNEXPECTED_ISSUE = 'unexpected_issue',
  PRINT_FAILED = 'print_failed',
  CREATE_PDF_FAILED = 'create_pdf_failed',
  FIND_PATIENT_FAILED = 'find_patient_failed',
  ENCOUNTER_NOT_LOCKED = 'encounter_not_locked',
  INVALID_WORKER = 'invalid_worker',
}

export type FinishJobError = {
  message: JobFinishDetails;
  isValid: boolean;
};

export interface FinishJobInput {
  jobUUID: string;
  status: JobFinishStatus;
  results?: FileResult[];
  details?: JobFinishDetails;
  vimPatientId?: string;
  refactoredFlow?: boolean;
}

const FINISH_JOB_MUTATION = gql`
  mutation finishJob($input: FinishJobInput!) {
    finishJob(input: $input)
  }
`;

export async function finishJob(input: FinishJobInput): Promise<undefined> {
  const logger = getLogger({ scope: `finish-job-api:${input.jobUUID}` });

  try {
    const gqlClient = await CdeGqlClientManager.getClient();

    const result = await gqlClient.mutate<{ finishJob: undefined }, { input: FinishJobInput }>({
      mutation: FINISH_JOB_MUTATION,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
    return result.data?.finishJob;
  } catch (error: any) {
    const logData = { input, error };

    if (error.status === 409) {
      logger.warning('Failed to report finish since job is not registered under worker', logData);
    }

    logger.error('Failed to report finish job', logData);
  }
}
