import { ErrorCodes } from '..';
import { StandardError } from '../base-error';
export class MessageResultError extends StandardError {
  constructor(type: string, originalError: unknown) {
    super(
      `Message result for message of ${type} has returned an error`,
      ErrorCodes.INTERNAL_ERROR,
      { originalError },
    );
  }
}
