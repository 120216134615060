import { analyticsManager } from './analyticsManager';
import { parseDataRequester } from './patientRequests';
import { ExtendedPatientRequestsDetailsResponse } from '../api/bff';

export const trackTabSwitch = ({
  requests,
  redirectedFrom,
  tabName,
}: {
  requests: {
    openRequests: ExtendedPatientRequestsDetailsResponse;
    allRequests: ExtendedPatientRequestsDetailsResponse;
  };
  redirectedFrom: string;
  tabName: string;
}): void => {
  analyticsManager.track('cde_tab_switch', {
    app_source_displayed: analyticsManager.getDatasource(),
    data_requester: parseDataRequester([
      ...(requests?.openRequests?.extendedPatientRequestsDetails ?? []),
      ...(requests?.allRequests?.extendedPatientRequestsDetails ?? []),
    ]),
    redirected_from: redirectedFrom,
    tab_name: tabName,
  });
};
