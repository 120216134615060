import { useEffect, useState } from 'react';
import { jobManager } from '../state';
import { JobContext } from '../types';

export const useJobPullInfo = (): Partial<boolean> => {
  const [job, setJob] = useState<boolean>(false);

  useEffect(() => {
    jobManager.subscribe((clonedContext: JobContext) => {
      setJob(clonedContext.jobUUID !== undefined);
    });
  }, []);

  return job;
};
