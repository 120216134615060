import { BffApi } from '../api';
import { JobFinishStatus, FinishJobError } from '../api/bff';
import { getLogger } from '../components/logger';
import { jobManager } from '../state';
import { FileResult, JobDetails } from '../types';
import { handleProcessJobError } from './error';
import { isFinishJobErrorIsValid } from './finish-job';
import { logCDERequestsReport } from './log';
import { shouldStopApp } from './stop-app';
import { getPatientEncountersHandler } from './job-types-handlers/patient-encounters';

export const processJob = async () => {
  let job: JobDetails | undefined;
  try {
    if (shouldStopApp()) {
      jobManager.clearAndNotify();
      return;
    }
    job = await BffApi.takeJob();
    if (!job) {
      jobManager.clearAndNotify();
      return;
    }

    jobManager.set({ ...job });
    const { jobUUID } = jobManager.getAll();

    const jobLogger = getLogger({ scope: `job-processing:${jobUUID}` });
    jobLogger.info('CDE worker took a job');

    try {
      let handleJobResult: Awaited<FileResult[]> = [];

      handleJobResult = await getPatientEncountersHandler();

      jobLogger.info('Job handled successfully');

      if (!handleJobResult.length) jobLogger.info('No files were written to S3');
      else jobLogger.info('Files written successfully to S3', { handleJobResult });

      await BffApi.finishJob({
        status: JobFinishStatus.SUCCESS,
        results: handleJobResult,
        jobUUID,
        vimPatientId: jobManager.get('vimPatientId'),
        refactoredFlow: true,
      });

      logCDERequestsReport({ job, jobLogger, status: JobFinishStatus.SUCCESS });

      jobLogger.info('Reported job as finished successfully');
    } catch (error: any) {
      const { isValid, message }: FinishJobError = isFinishJobErrorIsValid(error.message);
      const modifiedJob = { ...job, patient: { pseudonymizedID: job?.patient?.pseudonymizedId } };
      if (!isValid) {
        jobLogger.error('Failed to handle or report a job', { error, jobUUID, modifiedJob });
      } else {
        jobLogger.info('Job processing finished with a valid error', {
          error,
          jobUUID,
          modifiedJob,
        });
      }

      if (!error?.fileResults?.length)
        jobLogger.info('Job Failure - No files were written to S3', {
          error,
          jobUUID,
          modifiedJob,
        });
      else
        jobLogger.info('Job Failure - Files written successfully to S3', {
          fileResults: error?.fileResults,
          jobUUID,
          modifiedJob,
          error,
        });

      await BffApi.finishJob({
        status: JobFinishStatus.FAIL,
        details: message,
        results: error.fileResults,
        jobUUID,
        vimPatientId: jobManager.get('vimPatientId'),
        refactoredFlow: true,
      });

      logCDERequestsReport({ job, jobLogger, status: JobFinishStatus.FAIL });
    }
  } catch (error) {
    handleProcessJobError({ error, job });
  } finally {
    jobManager.clear();

    // eslint-disable-next-line no-unsafe-finally
    return !!job;
  }
};
