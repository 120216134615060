import { analyticsManager } from './analyticsManager';
import { parseDataRequester } from './patientRequests';
import { ExtendedPatientRequestsDetailsResponse } from '../api/bff';

export const trackChangePage = ({
  requests,
  currentPage,
  pageClicked,
}: {
  requests: {
    openRequests: ExtendedPatientRequestsDetailsResponse;
    allRequests: ExtendedPatientRequestsDetailsResponse;
  };
  currentPage: number;
  pageClicked: number;
}): void => {
  analyticsManager.track('cde_change_page', {
    app_source_displayed: analyticsManager.getDatasource(),
    data_requester: parseDataRequester([
      ...(requests?.openRequests?.extendedPatientRequestsDetails ?? []),
      ...(requests?.allRequests?.extendedPatientRequestsDetails ?? []),
    ]),
    current_page: currentPage,
    page_clicked: pageClicked,
  });
};
