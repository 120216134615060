export function getTwoLevelObjectPopulatedKeys(params: object) {
  if (!params) {
    return [];
  }
  const finalKeys: string[] = [];

  for (const [key, value] of Object.entries(params)) {
    if (!value) {
      continue;
    }

    finalKeys.push(key);
    if (typeof value === 'object') {
      for (const [subKey, secondValue] of Object.entries(value)) {
        if (!secondValue) {
          continue;
        }

        finalKeys.push(`${key}.${subKey}`);
      }
    }
  }

  return finalKeys;
}
