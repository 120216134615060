import { ActionTypes } from '@getvim-os/types';
import { adapterActionApi } from '../../../api/adapter';
import { getLogger } from '../../../components/logger';
import { featureFlagsClient } from '../../../services';
import { organizationManager } from '../../../state';
import { createJsonFile } from '../../file/createJsonFile';
import { writeToS3 } from '../../s3';

interface GenerateLabsAndVitalsFile {
  encounterId: string;
  patientId: string;
  fileName: string;
  metadata: any;
  jobUUID: string;
}

type FilteredLabResult = {
  results: ActionTypes.GetEncounterLabResultsAction.LabResult[];
  labName: string;
  orderDate: string;
  collectionDate?: string | undefined;
  resultDate?: string | undefined;
};

type FilteredVitalResult = {
  name: string;
  uom: string;
  value: string;
};

export const generateLabsAndVitalsFile = async ({
  encounterId,
  patientId,
  fileName,
  metadata,
  jobUUID,
}: GenerateLabsAndVitalsFile): Promise<string | undefined> => {
  const logger = getLogger({
    scope: `labsAndVitals:${jobUUID}`,
  });

  const organizationId = organizationManager.get('organizationId');
  const shouldGenerateFileFlag = await featureFlagsClient.getFlag({
    flagName: 'generateLabsAndVitalsFile',
    defaultValue: false,
    flagContext: { organizationId },
  });
  if (!shouldGenerateFileFlag) {
    logger.info('Not generating labs and vitals file, feature flag is false.');
    return;
  }

  let filteredLabResults: FilteredLabResult[];
  try {
    const rawLabResults = await adapterActionApi.getEncounterLabResults({
      encounterId,
      patientId,
    });
    filteredLabResults = rawLabResults.map((resultOrder) => {
      return {
        ...resultOrder,
        results: resultOrder.results.filter((result) => result.value !== ''),
      };
    });
    logger.info(
      `successfully got ${filteredLabResults.length} lab results for encounter ${encounterId}`,
    );
  } catch (error) {
    logger.error('Encounter had error to get lab results', {
      encounterId,
      patientId,
      error,
    });
    filteredLabResults = [];
  }

  let filteredVitals: FilteredVitalResult[];
  try {
    const rawVitals = await adapterActionApi.getEncounterVitals({ encounterId, patientId });
    filteredVitals = rawVitals.map((vital) => constructFilteredVital(vital));

    logger.info(`successfully got ${filteredVitals.length} vitals for encounter ${encounterId}`);
  } catch (error) {
    logger.error('Encounter had error to get vitals', {
      encounterId,
      patientId,
      error,
    });
    filteredVitals = [];
  }

  try {
    const vitalLabsFileName = `${fileName}_vital_labs`;
    const vitalLabFileData = {
      ...metadata,
      labResults: filteredLabResults,
      vitals: filteredVitals,
    };

    const vitalLabFile = createJsonFile({
      fileName: vitalLabsFileName,
      data: vitalLabFileData,
    });

    const writeToS3Params = { jobUUID, files: [vitalLabFile] };
    const res = await writeToS3(writeToS3Params);
    if (res.length === 0) {
      logger.error('Could not upload labAndVitals file to S3', {
        encounterId,
        patientId,
      });
      return;
    }
    const filePath = res[0].path;
    logger.info('successfully written vital labs file', {
      filePath,
      encounterId,
      patientId,
    });
    return filePath;
  } catch (error) {
    logger.error('Encounter had error to create or write lab results or vitals file to s3', {
      encounterId,
      patientId,
      error,
    });
  }
};

function constructFilteredVital(vital: ActionTypes.GetEncounterVitalsAction.Vital): {
  name: string;
  uom: string;
  value: string;
} {
  const { name, uom, value, notTaken, reason } = vital;
  if (notTaken) {
    return { name, uom: '', value: reason ? `Not taken - ${reason}` : 'Not taken' };
  }
  return { name, uom, value };
}
