import React, { useCallback } from 'react';
import { StyledTableCell, StyledTableRow, TooltipedEllipsis } from './StyledComponents';
import { OpenRequestsTableRowProps, ActionAnalyticsType } from '../../types/table';
import { Button } from '@getvim/atomic-ui';
import { RejectModal } from '../modals/RejectModal';
import { formatDataRequester, formatToLocalDate } from '../../formatters';
import './Tables.less';

export const OpenRequestsTableRow: React.FC<OpenRequestsTableRowProps> = ({
  row,
  handleActionClick,
  setRejectedRow,
  rejectedRows,
  index,
}) => {
  const isFirstRow = index === 0;

  const formattedDataRequester = formatDataRequester(row.dataRequester, row.originalRequester);

  const handleButtonClick = useCallback(() => {
    handleActionClick(row.patientRequestId, ActionAnalyticsType.ACTION_CLICKED);
  }, [handleActionClick, row.patientRequestId]);

  return (
    <StyledTableRow>
      <StyledTableCell className={`two-rows-ellipsis-cell ${isFirstRow && 'first-row'}`}>
        <TooltipedEllipsis text={row.patientName} maxLength={45} topTooltip={!isFirstRow} />
      </StyledTableCell>
      <StyledTableCell>{row.patientDateOfBirth}</StyledTableCell>
      <StyledTableCell
        className={`two-rows-ellipsis-cell no-word-break ${isFirstRow && 'first-row'}`}
      >
        <TooltipedEllipsis text={formattedDataRequester} maxLength={35} topTooltip={!isFirstRow} />
      </StyledTableCell>
      <StyledTableCell>{row.chartRequestRange}</StyledTableCell>
      <StyledTableCell>{formatToLocalDate(row.reviewBefore, 'MM/DD/YYYY')}</StyledTableCell>
      <StyledTableCell>
        <div>
          <RejectModal
            requesterName={formattedDataRequester}
            requestId={row.patientRequestId}
            rejectCallback={setRejectedRow}
            handleActionClick={handleActionClick}
            disclosure={
              <Button
                className="reject-button no-padding"
                buttonType="tiny"
                bgColor={rejectedRows.includes(row.patientRequestId) ? 'none' : 'themedOutline'}
                text={rejectedRows.includes(row.patientRequestId) ? 'Rejected' : 'Reject'}
                disabled={rejectedRows.includes(row.patientRequestId) ? true : false}
                onClick={handleButtonClick}
              />
            }
          />
        </div>
      </StyledTableCell>
    </StyledTableRow>
  );
};
