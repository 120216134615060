import axios from 'axios';
import { FileMetadata, FileResult } from '../../types';
import { BffApi } from '../../api';
import { auditFileMetadataWritingToS3 } from '../audit';
import { jobManager } from '../../state';

interface WriteToS3Params {
  files: FileMetadata[];
  jobUUID: string;
}

export const prepareUploadUrls = async (files: Omit<FileMetadata, 'data'>[], jobUUID?: string) => {
  const uploadSpecs =
    (await BffApi.getUploadSpecs({
      files: files.map(({ name, extension }) => ({ name, extension })),
      jobUUID: jobUUID ?? jobManager.get('jobUUID'),
    })) || [];

  return uploadSpecs;
};

export const writeToS3 = async ({ files, jobUUID }: WriteToS3Params): Promise<FileResult[]> => {
  const jobContext = jobManager.getAll();
  const uploadSpecs = await prepareUploadUrls(files, jobUUID);

  await Promise.all(
    uploadSpecs.map(async ({ presignedKey }, i) => {
      let success = false;

      try {
        await axios({
          method: 'put',
          url: presignedKey,
          data: files[i].data,
          headers: { 'Content-Type': 'multipart/form-data' },
        });

        success = true;
      } catch (error) {
        success = false;
        throw error;
      } finally {
        await auditFileMetadataWritingToS3({ file: files[i], success, jobContext });
      }
    }),
  );

  return uploadSpecs.map<FileResult>(({ path }) => ({ path }));
};
