// eslint-disable-next-line import/named
import { Interval, RateLimiter } from 'limiter';

export const rateLimiterId = Symbol.for('RateLimiter');

export const getRateLimiter = (
  tokensPerInterval = 30,
  interval = 'min' as Interval,
  fireImmediately = true,
) => {
  return new RateLimiter({
    tokensPerInterval,
    interval,
    fireImmediately,
  });
};
