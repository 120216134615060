import { gql } from '@apollo/client';
import { PostAuditLogInput } from '@getvim/platform-types';
import { ScopedLogger } from '@getvim/vim-connect-logger';
import { CdeGqlClientManager } from './gqlClient';
import { RecursivePartial } from '../../types';
import { getLogger } from '../../components/logger';

const POST_AUDIT_LOG_MUTATION = gql`
  mutation postAuditLog($input: PostAuditLogInput!) {
    postAuditLog(input: $input)
  }
`;

export async function postAuditLog(input: RecursivePartial<PostAuditLogInput>): Promise<void> {
  const auditsLogger = getLogger({ scope: 'audits' });

  try {
    validatePostAuditLogInput(input, auditsLogger);

    const gqlClient = await CdeGqlClientManager.getClient();
    await gqlClient.mutate<void, { input: RecursivePartial<PostAuditLogInput> }>({
      mutation: POST_AUDIT_LOG_MUTATION,
      variables: { input },
      fetchPolicy: 'no-cache',
    });
  } catch (error) {
    auditsLogger.warning('failed to post audit log', { error, input });
  }
}

const validatePostAuditLogInput = (
  input: RecursivePartial<PostAuditLogInput>,
  auditsLogger: ScopedLogger,
) => {
  const { affectedEntity } = input;
  const { id } = affectedEntity || {};
  const { technicalId } = id || {};

  if (!technicalId) auditsLogger.warning('missing vimPatientId', { input });
};
