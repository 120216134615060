import { ActionResult } from '@getvim/platform-types';
import {
  ActionName,
  AffectedEntityType,
  AuditFileMetadataWritingToS3,
  AuditGetPatientAction,
  AuditGetPatientEncountersActionProps,
} from '../../types';
import { postAuditLog } from '../../api/bff';

export const auditFileMetadataWritingToS3 = async ({
  file,
  success,
  jobContext,
}: AuditFileMetadataWritingToS3) => {
  const { name, extension } = file;

  const fileName = `${name}.${extension}`;
  const affectedFieldName = `Sending the patient data to Vim. ${fileName}`;
  const actionResult = success ? ActionResult.SUCCESS : ActionResult.FAILED;

  return await postAuditLog({
    actionName: ActionName.CDEDisclosureOfInformation,
    actionResult,
    requester: jobContext.requester,
    affectedEntity: {
      type: AffectedEntityType.Patient,
      affectedFields: [{ name: affectedFieldName }],
      id: { technicalId: jobContext.vimPatientId },
    },
  });
};

export const auditGetPatientEncounterMetadata = async ({ success, jobContext }) => {
  return await postAuditLog({
    actionName: ActionName.CDEGetPatientEncounterMetadataDetails,
    actionResult: success ? ActionResult.SUCCESS : ActionResult.FAILED,
    requester: jobContext.requester,
    affectedEntity: {
      type: AffectedEntityType.Patient,
      affectedFields: [
        { name: 'Get the patient encounter metadata to share encounter identifiers' },
      ],
      id: { technicalId: jobContext.vimPatientId },
    },
  });
};

export const auditGetPatientEncounterDetails = async ({ success, jobContext }) => {
  return await postAuditLog({
    actionName: ActionName.CDEGetPatientEncounterDetails,
    actionResult: success ? ActionResult.SUCCESS : ActionResult.FAILED,
    requester: jobContext.requester,
    affectedEntity: {
      type: AffectedEntityType.Patient,
      affectedFields: [{ name: 'Get the patient encounter details to share encounter notes' }],
      id: { technicalId: jobContext.vimPatientId },
    },
  });
};

export const auditGetPatientEncountersAction = async ({
  success,
  jobContext,
}: AuditGetPatientEncountersActionProps) => {
  await postAuditLog({
    actionName: ActionName.CDEPatientEncounterList,
    actionResult: success ? ActionResult.SUCCESS : ActionResult.FAILED,
    requester: jobContext.requester,
    affectedEntity: {
      type: AffectedEntityType.Patient,
      id: { technicalId: jobContext.vimPatientId },
      affectedFields: [
        {
          name: `Get the patient's encounter list and metadata from ${jobContext.fromDate} to ${jobContext.untilDate}`,
        },
      ],
    },
  });
};

export const auditGetPatientAction = async ({ success, jobContext }: AuditGetPatientAction) => {
  await postAuditLog({
    actionName: ActionName.CDEGetPatient,
    requester: jobContext.requester,
    actionResult: success ? ActionResult.SUCCESS : ActionResult.FAILED,
    affectedEntity: {
      type: AffectedEntityType.Patient,
      affectedFields: [{ name: 'Get the patient details for patient, CDE prerequisite' }],
      id: { technicalId: jobContext.vimPatientId },
    },
  });
};
